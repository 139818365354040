import { GET_OFFICE_SUCCESSFUL, OFFICE_PROCESSING, STOP_OFFICE_PROCESSING } from "./type";

const defaultReducer = {
  processing: false,
  officeData: null,
};

const officeReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_OFFICE_SUCCESSFUL:
      return {
        ...state,
        processing: false,
        officeData: action.payload,
      };

    case OFFICE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    case STOP_OFFICE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default officeReducer;
