import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../asset/css/clients";
import { styled } from "@mui/material/styles";

import Switch, { SwitchProps } from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { changeNoteStatus, getNoteList } from "../../helper/API/cliente";
import Loader from "../Loader";
import { toast } from "react-toastify";
import RecordFound from "../RecordFound/RecordFound";
const Img = styled("img")({
  display: "block",
});

const IOSSwitch = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 56,
  height: 25,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    color: "rgba(53, 53, 53, 0.7)",
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: "#14274E",
      "& + .MuiSwitch-track": {
        background: "linear-gradient(44.42deg, #FF543E 8.04%, #C837AB 92.29%)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "red",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "rgba(53, 53, 53, 0.2)",
      //   opacity: 0.2,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.2,
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
    // right: 0,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "rgba(53, 53, 53, 0.2)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ClientsNote = () => {
  const classes = useStyles();
  const [Data, setData] = useState([]);
  const [switchVal, setSwitchVal] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getNoteList().then(res => {
      if (res.success) {
        setData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res.message);
      }
    });
  }, []);

  const handleChangePago = (e, id) => {
    setSwitchVal(e.target.checked);
    const submitData = { status: e.target.checked, id: id };
    // console.log("submitData", submitData);
    changeNoteStatus(submitData).then(res => {
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  // console.log("switchVal", switchVal);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.root}>
          {Data.length ? (
            Data?.map((obj, indx) => (
              <Card variant="elevation" className={classes.card}>
                <CardContent>
                  <Box p={0} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box flex={1} p={0}>
                      <p className={classes.notes}>
                        <b>Valor: </b>
                        {/* R$ */}
                        {obj.value}
                      </p>
                      <p className={classes.notes}>
                        <b>Nota: </b>
                        {obj.noteType}
                      </p>{" "}
                      <p className={classes.notes}>
                        <b>Cliente: </b>
                        {obj.client}
                      </p>{" "}
                      <p className={classes.notes}>
                        <b>Data: </b>
                        {obj.date}
                      </p>{" "}
                    </Box>
                    <Box p={0}>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={obj.paid} onChange={e => handleChangePago(e, obj.id)} />}
                        className={classes.label}
                        label="Pago?"
                        labelPlacement="top"
                      />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <RecordFound label={"Nenhum registro foi encontrado"} />
          )}
        </div>
      )}
    </>
  );
};

export default ClientsNote;
