import React from "react";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";

const StyledOutlinedInput = ({ placeholder, src, onChange, name, defaultValue }) => {
  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <OutlinedInput
          name={name}
          id="outlined-adornment-weight"
          placeholder={placeholder}
          // value={values.weight}
          defaultValue={defaultValue}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end">
              <img src={src} />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
        />
      </FormControl>
    </div>
  );
};

export default StyledOutlinedInput;
