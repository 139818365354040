import React, { useState, useEffect } from "react";

import { BasicPage } from "../components/BasicPage";
import Person from "@mui/icons-material/Person";
import Calander from "../components/Agenda/Calendar";
import CalendarTask from "../components/Agenda/CalendarTask";
import { getTaskByMonth } from "../helper/API/advocate";
import moment from "moment/moment";
import { getAdvocateTask, getTaskCalendar } from "../helper/API/task";
import Loader from "../components/Loader";
import PlusBottom from "../components/PlusBottom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const date = moment().format("YYYY-MM-DD");
  const [month, setMonth] = useState();
  const [task, setTask] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [taskDate, setTaskDate] = useState(date);
  const [taskYear, setTaskYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  // console.log("month", moment(taskDate).format("-MM-DD"));
  // console.log("taskYear", taskYear);
  // console.log("taskDate", taskDate);
  let newTaskDate = `${taskYear}${moment(taskDate).format("-MM-DD")}`;
  // console.log("newTaskDate", newTaskDate);
  useEffect(() => {
    setLoading(true);
    if (month) {
      const submitData = { month: month[0].number, year: taskYear };
      // console.log("submitData", submitData);
      getTaskByMonth(submitData).then(task => {
        if (task.success) {
          setTask(task.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(task.message);
        }
        // console.log("get advocate calendar task", task);
      });
    }
    const submitDate = { date: newTaskDate };
    // console.log("submitDate :::", submitDate);
    setLoading(true);
    getTaskCalendar(submitDate).then(res => {
      // console.log("get advance calendar tasks", res);
      if (res.success) {
        setLoading(false);
        setTaskData(res.data.getTask);
      } else {
        setLoading(false);
        // toast.error(res.message);
      }
    });
  }, [month, taskDate, newTaskDate]);

  const handleClick = () => {
    navigate("/home/tarefas-semana");
  };
  return (
    <>
      <PlusBottom url="/home/tarefas-semana" handleClick={handleClick} />
      <Calander
        setMonth={setMonth}
        task={task}
        setTaskDate={setTaskDate}
        taskData={taskData}
        setTaskYear={setTaskYear}
        taskYear={taskYear}
      />

      {loading ? <Loader /> : <CalendarTask taskData={taskData} />}
    </>
  );
};
