import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Menu, MenuItem, Button, Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    backgroundColor: "#F3F2F7",
    boxShadow: "0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 9px 13px rgb(0 0 0 / 49%)",
    borderRadius: "11.1148px",

    //   boxShadow: "0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 3px 14px 2px rgba(0,0,0,0.15)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#F3F2F7 !important",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
  // "& .MuiPaper-root": {
  //   borderRadius: 6,
  //   marginTop: theme.spacing(1),
  //   minWidth: 180,
  //   color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
  //   boxShadow:
  //     "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  //   "& .MuiMenu-list": {
  //     padding: "4px 0",
  //   },
  //   "& .MuiMenuItem-root": {
  //     "& .MuiSvgIcon-root": {
  //       fontSize: 18,
  //       color: theme.palette.text.secondary,
  //       marginRight: theme.spacing(1.5),
  //     },
  //     "&:active": {
  //       backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  //     },
  //   },
  // },
}));

export default function SelectMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        sx={{
          width: "100%",
          textAlign: "left !important",
          textTransform: "capitalize",
          justifyContent: "space-between",
          padding: "13px 14px !important",
          color: "#11142D",
        }}
        endIcon={<KeyboardArrowDownIcon />}>
        Selecione a despesa
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={handleClose} disableRipple>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <TaskAltIcon style={{ color: "#73469980" }} />
            <Typography sx={{ color: "#5E6C93" }} variant="body1">
              Advogados
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <TaskAltIcon style={{ color: "#73469980" }} />
            <Typography sx={{ color: "#5E6C93" }} variant="body1">
              Funcionários
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <TaskAltIcon style={{ color: "#73469980" }} />
            <Typography sx={{ color: "#5E6C93" }} variant="body1">
              Impostos
            </Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
