import React from "react";
import { Container } from "@mui/system";
import { Card, CardContent, FormControl, Grid, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";

const TimeStampCard = ({ time }) => {
  return (
    <div>
      <Container sx={{ padding: 0, marginY: "1rem" }}>
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
          <div
            style={{
              border: "2px solid #F4F8FC",
              padding: "0.3rem 1rem",
              borderRadius: "58.8235px",
              backgroundColor: "#F4F8FC",
              color: "#636C84",
            }}>
            {time}
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default TimeStampCard;
