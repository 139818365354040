import axios from "axios";
import { BASE_URL } from "../config/index";
import { store } from "../redux/store";

export const ApiCall = (url, method, data = null, headers = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${BASE_URL}${url}`,
      data,
      headers: {
        ...headers
      },
    })
      .then(resolve)
      .catch(reject);
  });
};
// const token = store.getState().loginUsers?.loginUser?.data?.data?.jwtTokens?.accessToken;
// console.log("token", token);

export const AfterAuthApi = (url, method, data = null, headers = {}) => {
  return new Promise((resolve, reject) => {
    const token = store.getState().loginUsers?.loginUser?.jwtTokens?.accessToken;
    // console.log("token", store.getState().loginUsers);
    axios({
      method,
      url: `${BASE_URL}${url}`,
      data,
      headers: {
        Authorization: "Bearer " + token,
        ...headers,
      },
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        if (err.request) {
          if (err.request.status === 403) {
            localStorage.clear();
            window.location.href = window.location.origin;
          }
        }

        reject(err);
      });
  });
};
