import { ApiCall } from "../../helper";
import { getAdvocate } from "../../helper/API/advocate";
import { getAllClient } from "../../helper/API/cliente";
import { GET_CLIENT_LIST, STOP_CLIENT_PROCESSING, CLIENT_PROCESSING } from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setClientList = payload => {
  // console.log("payload ser", payload);
  return {
    type: GET_CLIENT_LIST,
    payload,
  };
};

export const clientList = payload => dispatch => {
  return new Promise((resolve, reject) => {
    // console.log("payload", payload);
    dispatch({ type: CLIENT_PROCESSING });
    getAllClient()
      .then(client => {
        // console.log("client", client);
        dispatch({
          type: GET_CLIENT_LIST,
          payload: client.data.findClient,
        });
        resolve(client.data.findClient);
      })
      .catch(error => {
        dispatch({ type: STOP_CLIENT_PROCESSING });
        // console.log("error in login action", error.message);
      });
  });
};
