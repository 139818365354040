import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Navbar from "../shareComponent/Navbar";
import { AppBar } from "./AppBar";
import Box from "@mui/material/Box";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShowMobileAppScreen from "../pages/ShowMobileAppScreen";
export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div style={{ position: "relative" }}>
      <div className="protectedOutlet">
        <Navbar
          pages={[
            // { label: "Settings", path: "settings" },
            // { label: "Profile", path: "profile" },
            { label: "Escritório", path: "office", img: "/assets/images/office.png" },
            { label: "Agenda", path: "profile", img: "/assets/images/agenda.png" },
            { label: "Clientes", path: "clients", img: "/assets/images/customers.png" },
            { label: "Chats", path: "chats", img: "/assets/images/chats.png" },
            { label: "Processos", path: "processos", img: "/assets/images/law_suit.png" },
            { label: "Financeiro", path: "financeiro", img: "/assets/images/financial.png" },
          ]}
        />
        {outlet}
      </div>
      <div className="mobileWebBlock">
        <ShowMobileAppScreen />
      </div>
      {/* <Box sx={{ position: "absolute", bottom: "0px", right: 0, zIndex: 99 }} px={1} display={"flex"} justifyContent={"end"}>
        <AddCircleIcon sx={{ fontSize: "4rem", color: "red", cursor: "pointer" }} />
      </Box> */}
    </div>
  );
};
