import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StyledButton from "../StyledButton";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { registerAdvocate, socialAuth } from "../../helper/API/auth";
import { Button, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import * as registerAction from "../../redux/Register/action";
import * as loginAction from "../../redux/login/action";
import { shouldRedirectToPlan } from "../../helper/validation/userPlan";

const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  position: "relative",
  left: "-10px",
  top: "-20px",
});

const OtpPage = ({ loginData, regiterSuccess, setLoginUser, loginLocalData, userInfo, isLoggedIn, userData }) => {
  const navigate = useNavigate();
  const params = useLocation();
  const [Otp, setOtp] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  console.log("loginData", loginData);


  React.useEffect(() => {
    console.log('isLoggedIn : ', isLoggedIn);
    console.log('userData : ', userData);
    if (isLoggedIn && userData) {
      if (shouldRedirectToPlan(userData)) {
        setLoading(false);
        navigate("/plan", { replace: true });
      } else {
        setLoading(false);
        loginLocalData({ email: loginData.formValues.email, password: loginData.formValues.password });
        navigate("/home", { replace: true });
      }
    }
  }, [isLoggedIn]);


  const handleSubmit = event => {
    setLoading(true);

    if (Otp.length === 6) {
      let submitData = {
        name: loginData.formValues.name,
        email: loginData.formValues.email,
        telephone: loginData.formValues.telephone,
        officeType: loginData.occupationType,
        password: loginData.formValues.password,
      };
      console.log("submitData", submitData);

      let confirmationResult = window.confirmationResult;
      console.log('confirmationResult : ', confirmationResult);
      confirmationResult
        .confirm(Otp)
        .then(result => {
          console.log('confirmationResult confirm : result : ', result);
          // User signed in successfully.
          const user = result.user;
          console.log("loginData", loginData);
          if (user && loginData.socialRes.providerId === "" && loginData.socialRes.provider === "") {
            registerAdvocate(submitData).then(res => {
              console.log("res", res);
              if (res.success) {
                setLoginUser(res.data);
                if (
                  shouldRedirectToPlan(res.data.user)
                ) {
                  // navigate("/plan", { replace: true });
                } else {
                  setLoginUser(res.data);
                  // navigate("/home", { replace: true });
                  toast.success(res.message);
                  userInfo(null);
                }
              } else {
                toast.error(res.message);
                navigate("/register");
                setLoading(false);
                userInfo(null);
              }
            });
          } else {
            let submitData = {
              name: loginData.formValues.name,
              email: loginData.formValues.email,
              telephone: loginData.formValues.telephone,
              officeType: loginData.occupationType,
              // password: loginData.formValues.password,
              providerId: loginData.socialRes.providerId,
              provider: loginData.socialRes.provider,
            };
            socialAuth(submitData).then(res => {
              console.log("socialAuth : res", res);
              if (res.success) {
                setLoginUser(res.data);
                if (shouldRedirectToPlan(res.data.user)) {

                } else {
                  setLoginUser(res.data);
                  setLoading(false);
                  toast.success(res.message);
                  userInfo(null);
                }
              } else {
                toast.error(res.message);
                navigate("/register");
                setLoading(false);
                userInfo(null);
              }
            }).catch((error) => {
              console.log("socialAuth : error : ", error.message);
              toast.error(error.message);
              setLoading(false);
            });
          }
          // ...
        })
        .catch(error => {
          console.log("confirm : error : ", error.message);
          toast.error(error.message);
          setLoading(false);
        });
    }
  };

  const resendOTP = () => {
    // console.log("clicked resend otp");

    toast.success("OTP enviar para o e-mail cadastrado");
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: 5, py: 5, px: 5, backgroundColor: "#fff" }}>
        <Link to="/register">
          <Img alt="complex" src="/assets/images/backbtn.png" sx={{ height: 150 }} />
        </Link>
        <Container>
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography component="h6" variant="h5" sx={{ color: "#92929D", fontSize: "16px", textAlign: "center", mb: 3 }}>
              Digite o CÓDIGO que é recebido no seu celular{" "}
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                placeholder="Digite seu CÓDIGO"
                required
                fullWidth
                id="otp"
                label="CÓDIGO"
                name="forgotOtp"
                autoComplete="otp"
                autoFocus
                onChange={e => setOtp(e.target.value)}
              />

              {/* <StyledButton onClick={handleSubmit} fullWidth variant={"contained"} btnText={"Recuperar Senha"} sx={{ mt: 3, mb: 2 }} /> */}
              {/* {Otp.length === 6 && ( */}
              <Button fullWidth variant={"contained"} sx={{ mt: 3, mb: 2 }} onClick={handleSubmit} disabled={loading || Otp.length !== 6}>
                Registro
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
              {/* )} */}
            </Box>
          </Box>
        </Container>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  // console.log("state", state.registerReducer.userInfo);
  return {
    isLoggedIn: state?.loginUsers?.isLoggedIn,
    userData: state?.loginUsers?.loginUser?.user,
    loginData: state?.registerReducer?.userInfo,
  };
};

const mapDispatchToProp = dispatch => {
  return {
    regiterSuccess: data => dispatch(registerAction.setRegisterSuccessUser(data)),
    loginLocalData: data => dispatch(loginAction.setData(data)),
    setLoginUser: data => dispatch(loginAction.setLoginUser(data)),
    userInfo: data => dispatch(registerAction.setRegisterInfo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(OtpPage);
