import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import { experimentalStyled as styled } from "@mui/material/styles";
import CustomTabPanel from "../components/Inputs/CustomTabPanel";
import ClientsList from "../components/Clients/ClientsList";
import ClientsNote from "../components/Clients/ClientsNote";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PlusBottom from "../components/PlusBottom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ErrorPage from "./ErrorPage";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,
      //   bottom: "4px",
      //   left: "6px !important",
      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
  },
  actionContainer: {
    background: "#D6D9FF",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
    // padding: "8px 15px",
  },
  title: {
    color: "#0A0615",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 22,
    margin: "0 !important",
  },
  subtitle: {
    color: "#404B52",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 16,
    margin: "0 !important",
  },
  card: {
    marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
});
const Clients = ({ permission }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/home/cliente/add");
  };
  const tabs = ["Clientes", "Notas"];
  return (
    <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={12} py={4}>
      {permission?.isOwner && permission?.clientMgt ? (
        <>
          <CustomTabPanel handleChange={handleChange} tabs={tabs} value={value} />

          {value === 0 && <PlusBottom url="/cliente/add" handleClick={handleClick} />}
        </>
      ) : (
        ""
      )}

      {permission?.isOwner && permission?.clientMgt ? value === 0 ? <ClientsList /> : <ClientsNote /> : <ErrorPage />}
    </Box>
  );
};

const mapStateToProps = state => {
  // console.log("state", state);
  return {
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProps)(Clients);
