import { REGISTER_REQ_DATA_OBJECT, REGISTER_PROCESSING, REGISTER_SUCCESS_USER_DATA } from "./type";

export const setRegisterInfo = payload => {
  console.log("payload setRegisterInfo", payload);
  return {
    type: REGISTER_REQ_DATA_OBJECT,
    payload,
  };
};
export const setRegisterSuccessUser = payload => {
  console.log("payload setRegisterSuccessUser", payload);
  return {
    type: REGISTER_SUCCESS_USER_DATA,
    payload,
  };
};

// export const advocateList = payload => dispatch => {
//   return new Promise((resolve, reject) => {
//     // console.log("payload", payload);
//     dispatch({ type: ADVOCATE_PROCESSING });
//     getAdvocate()
//       .then(advocate => {
//         // console.log("advocate", advocate);
//         dispatch({
//           type: GET_ADVOCATE_LIST,
//           payload: advocate.data,
//         });
//         resolve(advocate.data);
//       })
//       .catch(error => {
//         dispatch({ type: STOP_ADVOCATE_PROCESSING });
//         // console.log("error in login action", error.message);
//       });
//   });
// };
