import React, { useState } from "react";
import { Typography, Box, Grid, NativeSelect, Button, Input, FormControl, TextField, MenuItem, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import NameModal from "./NameModal";
import OfficeModal from "./OfficeModal";
const ariaLabel = { "aria-label": "description" };
const FinanceSelect = ({ transactionType, name, office, refresh, setRefresh, onSelectChange, selectState }) => {
  const [show, setShow] = useState(false);
  const [showOffice, setShowOffice] = useState(false);

  const handleClickOpenName = () => {
    setShow(true);
  };
  const handleClickOpenOffice = () => {
    setShowOffice(true);
  };
  // console.log("selectState.transactionType.value", selectState.transactionType.value);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} sx={{ mt: 3 }}>
          <FormControl fullWidth>
            <Typography variant="body1" my={1}>
              Tipo
            </Typography>
            <TextField id="standard-select-currency" select name="transactionType" variant="standard" onChange={onSelectChange}>
              {transactionType?.map((obj, indx) => (
                <MenuItem value={obj} sx={{ color: "#5031A9", fontWeight: 500, fontFamily: "Poppins" }}>
                  {obj.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <Typography variant="body1" my={1}>
            Nome:
          </Typography>
          <Input fullWidth placeholder="Nome" name="name" inputProps={ariaLabel} onChange={onSelectChange} />
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <FormControl fullWidth>
            <Typography variant="body1" my={1}>
              Nome do lançamento
            </Typography>
            <TextField id="standard-select-currency" select name="userType" variant="standard" onChange={onSelectChange}>
              {name?.map((obj, indx) => (
                <MenuItem value={obj.id} sx={{ color: "#5031A9", fontWeight: 500, fontFamily: "Poppins" }}>
                  {obj.label}
                </MenuItem>
              ))}
              <MenuItem value={"Add"} onClick={handleClickOpenName} sx={{ color: "#5031A9", fontWeight: 500, fontFamily: "Poppins" }}>
                + Adicionar
              </MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }}>
          <FormControl fullWidth>
            <Typography variant="body1" my={1}>
              Cargo
            </Typography>
            <TextField id="standard-select-currency" select name="office" onChange={onSelectChange} variant="standard">
              {office?.map((obj, indx) => (
                <MenuItem value={obj.id} sx={{ color: "#5031A9", fontWeight: 500, fontFamily: "Poppins" }}>
                  {obj.label}
                </MenuItem>
              ))}
              <MenuItem value={"Add"} onClick={handleClickOpenOffice} sx={{ color: "#5031A9", fontWeight: 500, fontFamily: "Poppins" }}>
                + Adicionar
              </MenuItem>
            </TextField>
          </FormControl>
        </Grid>

        <Grid item md={12} sx={{ mt: 3 }}>
          <Typography variant="body1" my={1}>
            Valor:
          </Typography>
          <Input fullWidth placeholder="R$ 250,00" name="amount" inputProps={ariaLabel} onChange={onSelectChange} />
        </Grid>

        {(selectState.transactionType.value === "DESPESAS_FIX" || selectState.transactionType.value === "ENTRADAS_FIX") && (
          <Grid item md={12} sx={{ mt: 3 }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              {/* <InputLabel id="demo-simple-select-helper-label">Frequência</InputLabel> */}
              <TextField
                id="outlined-number"
                label="Frequência"
                type="number"
                name="frequency"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={onSelectChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Período</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="period"
                onChange={onSelectChange}
                label="Período">
                <MenuItem value="daily">Diário</MenuItem>
                <MenuItem value="weekly">Semanal</MenuItem>
                <MenuItem value="monthly">Mensal</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {show && <NameModal show={show} handleClose={() => setShow(false)} refresh={refresh} setRefresh={setRefresh} />}
        {showOffice && <OfficeModal show={showOffice} handleClose={() => setShowOffice(false)} refresh={refresh} setRefresh={setRefresh} />}
      </Grid>
    </div>
  );
};

export default FinanceSelect;
