import React from "react";
import { Avatar, AvatarGroup, Box, Button, Card, CardContent, MenuItem, TextareaAutosize, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import DropdownButton from "../components/Processos/DropdownButton";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid, InputAdornment, OutlinedInput, TextField } from "@mui/material";

import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import SwitchUnstyled, { switchUnstyledClasses } from "@mui/base/SwitchUnstyled";
import { Container } from "@mui/system";

const currencies = [
  {
    value: "skype",
    label: "Skype",
  },
];
const Reuniaos = [
  {
    value: "Dr. Júlio Rocha",
    label: "Dr. Júlio Rocha",
  },
  {
    value: "Dr. Paulo Roberto",
    label: "Dr. Paulo Roberto",
  },
  {
    value: "Dra. Renata Mello",
    label: "Dra. Renata Mello",
  },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "100%",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      border: "none",
      // borderColor: '#80bdff',
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const blue = {
  500: "#5031A9",
};

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const Img = styled("img")({
  display: "block",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 1,
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },
  },
  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const AdicionarCliente = () => {
  const [currency, setCurrency] = React.useState("skype");
  const [Reuniao, setReuniao] = React.useState("Reuniao");

  const classes = useStyles();
  const navigate = useNavigate();

  const handleChange = event => {
    setCurrency(event.target.value);
  };
  const handleReuniaoChange = event => {
    setReuniao(event.target.value);
  };

  const handleSave = () => {
    navigate("/home/adicionar-advogado");
  };

  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "end", marginBottom: "2rem" }}>
          {/* <Typography >Voltar</Typography> */}
          <Button variant="contained" sx={{ fontWeight: 600, fontFamily: "Poppins" }} onClick={handleSave}>
            Salvar
          </Button>
        </Box>

        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <Typography variant="h4">Pessoa física</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Nome" variant="outlined" fullWidth placeholder="Digite o nome" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="CNPJ/CPF" variant="outlined" fullWidth placeholder="Digite o CPF OU CNPJ" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="E-mail" variant="outlined" fullWidth placeholder="Digite o E-mail" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Telefone" variant="outlined" fullWidth placeholder="Digite o telefone" />
                  </Grid>
                </Grid>
                <Grid container mt={3} spacing={2}>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Endereço" variant="outlined" fullWidth placeholder="CEP" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Estado/Cidade" variant="outlined" fullWidth placeholder="Digite aqui" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Rua" variant="outlined" fullWidth placeholder="Digite aqui" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Bairro" variant="outlined" fullWidth placeholder="Digite aqui" />
                  </Grid>
                  <Grid item md={6}>
                    <TextField id="outlined-basic" label="Valor mensal" variant="outlined" fullWidth placeholder="Digite aqui o valor" />
                  </Grid>

                  <Grid item md={6}>
                    <TextField id="outlined-select-currency" select label="Select" value={Reuniao} onChange={handleReuniaoChange} fullWidth>
                      {Reuniaos.map(option => (
                        <MenuItem key={option.value} value={option.value} sx={{}}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src="/assets/images/check-circle.png" style={{ paddingRight: 5, height: 20 }} />
                            {option.label}
                          </div>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default AdicionarCliente;
