import { AfterAuthApi } from "../../helper";
import { GET_USER_PROFILE_SUCCESSFUL, GET_USER_PROFILE_PROCESSING, STOP_GET_USER_PROFILE_PROCESSING, GET_ALL_PROFILE } from "./type";
import { GET_PROFILE } from "../../helper/url";

export const getUserProfile = payload => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_PROFILE_PROCESSING });
    AfterAuthApi(GET_PROFILE, "post", payload)
      .then(profile => {
        // console.log("profile gget", profile);
        dispatch({
          type: GET_USER_PROFILE_SUCCESSFUL,
          payload: profile.data.data.user.plan,
        });
        dispatch({
          type: GET_ALL_PROFILE,
          payload: profile.data.data,
        });
        resolve(profile.data);
      })
      .catch(error => {
        dispatch({ type: STOP_GET_USER_PROFILE_PROCESSING });
        // console.log("error in login action", error.message);
      });
  });
};
