import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiFormControlLabel-label": {
      fontWeight: "700 !important",
    },
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiCardContent-root": {
      padding: "1rem !important",
    },
    // "& .MuiCardContent-root:last-child": {
    //   paddingBottom: "0 !important",
    // },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,
      //   bottom: "4px",
      //   left: "6px !important",
      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
      borderRadius: "19px !important",
    },
  },
  actionContainer: {
    background: "#D6D9FF",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
    // padding: "8px 15px",
  },
  title: {
    color: "#0A0615",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    margin: "0 !important",
  },
  subtitle: {
    color: "#404B52",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 14,
    margin: "0 !important",
  },
  card: {
    marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
  CardContent: {
    paddingBottom: "0 !important",
  },
  notes: {
    color: "#000000",
    padding: 3,
    fontSize: 16,
    margin: "0 !important",
    letterSpacing: 1,
  },
  label: {
    fontWeight: "700 !important",
  },
  clientCard: {
    padding: "2rem !important",
  },
  despesasCard: {
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "150% !important",
    color: "#8001FF !important",
  },
});
export default useStyles;
