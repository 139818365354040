import {
  DASHBOARD_GOALS,
  CAROUSAL_CARD,
  DELETE_CARD,
  GET_NOTIFICATION,
  GET_NOTIFICATION_COUNT,
  PLAN_PAYMENT_DETAILS,
  PLAN_PAYMENT_TABLE,
} from "../url";
import { AfterAuthApi } from "..";

export const getDashboardGoals = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DASHBOARD_GOALS, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getDashboardCards = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(CAROUSAL_CARD, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const deleteDashboardCards = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_CARD, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getDashboardNotification = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTIFICATION, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getNotificationCount = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTIFICATION_COUNT, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getPlanPaymentDetail = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(PLAN_PAYMENT_DETAILS, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getPaymentHistory = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(PLAN_PAYMENT_TABLE, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};
