import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { addPaymentUserType } from "../../helper/API/paymentTaskByAdvocate";
import { toast } from "react-toastify";

const NameModal = ({ show, handleClose, refresh, setRefresh }) => {
  const [label, setLabel] = React.useState("");

  const handleNameSubmit = () => {
    const submitData = { label };
    addPaymentUserType(submitData).then(res => {
      // console.log("res add name", res);
      if (res.success) {
        setRefresh(refresh + 1);
        handleClose();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>Nome do lançamento </div>
          <div>
            <CloseIcon onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "420px", display: "flex", mt: 3, mb: 3, justifyContent: "space-around" }}>
          <TextField
            id="outlined-basic"
            name="label"
            label="Nome do lançamento"
            variant="outlined"
            sx={{ mt: 2 }}
            onChange={e => setLabel(e.target.value)}
          />
          <Button variant="contained" onClick={handleNameSubmit} sx={{ mt: 2 }}>
            Salvar
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default NameModal;
