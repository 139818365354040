import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";

import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { CloseOutlined } from "@mui/icons-material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { taskDone } from "../../helper/API/task";
import { toast } from "react-toastify";
import DeleteTaskModal from "./DeleteTaskModal";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { alertAdvocateTask } from "../../helper/API/advocate";

const Img = styled("img")({
  display: "block",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // "& .MuiBox-root": {
    "& .MuiPaper-root": {
      // border: "5px solid red",
      backgroundColor: "#F8F5FA",
    },
    // },
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    // borderRadius: "14px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#F8F5FA",
    display: "flex",
  },
  content: {
    borderRadius: "20px",
    flexGrow: 5,
  },
});

const TaskListCard = ({ setRefresh, refresh, color, src, data }) => {
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${src})`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: `url('/assets/images/check-circle.png')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));
  const [view, setView] = useState(data.isAlert);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const classes = useStyles();
  const handleIcon = id => {
    setView(!view);
    const submitData = { id };
    alertAdvocateTask(submitData).then(res => {
      // console.log("res alerts", res);
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  // console.log("view", view);
  const handleClickOpen = id => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleDefault = id => {
    const submitData = { id };
    taskDone(submitData).then(res => {
      if (res.success) {
        toast.success(res.message);
        setRefresh(refresh + 1);
      } else {
        toast.error(res.message);
      }
    });
  };
  // console.log("data", data?.category?.color);
  return (
    <div>
      <Box sx={{ mt: 3 }} className={classes.root} px={8}>
        <Card variant="elevation" className={classes.card}>
          <div>
            {/* <Typography sx={{ mb: 1 }}>Reunião Cliente </Typography> */}
            <Typography px={3}>{data.startTime}</Typography>
          </div>
          <CardContent
            className={classes.content}
            sx={{ backgroundColor: data?.category?.color ? data?.category?.color : "#734699", borderRadius: "20px" }}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box sx={{ display: "flex", alignItems: "center", color: "#fff" }}>
                <div style={{ marginRight: "5rem", marginTop: "-3rem" }}>
                  <CloseOutlined onClick={() => handleClickOpen(data.id)} sx={{ cursor: "pointer" }} />
                </div>
                <div style={{ px: "3rem" }}>
                  <Link to={`/home/criar-tarefa/${data.id}`} style={{ textDecoration: "none" }} state={{ task: data }}>
                    <Typography sx={{ mb: 1, color: "#fff", fontWeight: 700, fontFamily: "Kumbh Sans", fontSize: "20px" }}>
                      {data.title}{" "}
                    </Typography>
                  </Link>
                  <Typography>{data.startTime}</Typography>
                </div>
              </Box>
              <Box p={0} sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked={data.isDone} onChange={() => handleDefault(data.id)} />}
                  className={classes.label}
                  labelPlacement="top"
                  sx={{ paddingRight: "1rem" }}
                />
                {/* <Link to={`/home/criar-tarefa/${data.id}`} style={{ textDecoration: "none" }} state={{ task: data }}>
                  <EditIcon
                    width={50}
                    height={50}
                    sx={{
                      maxWidth: "20px !important",
                      maxHeight: "20px !important",
                      posotion: "absolute",
                      background: "#fff",
                      color: "#734699",
                      padding: "12px",
                      borderRadius: "11px",
                      cursor: "pointer",
                      mr: "10rem",
                    }}
                    // onClick={() => handleEditAdvocate(obj)}
                  />
                </Link> */}
                {view ? (
                  <NotificationsActiveIcon
                    onClick={() => handleIcon(data.id)}
                    sx={{ color: "#fff", position: "relative", top: "-43%", zIndex: 5000 }}
                  />
                ) : (
                  <NotificationsOffIcon
                    onClick={() => handleIcon(data.id)}
                    sx={{ color: "#fff", position: "relative", top: "-43% ", zIndex: 5000 }}
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        {open && (
          <DeleteTaskModal handleClose={() => setOpen(false)} open={open} deleteId={deleteId} setRefresh={setRefresh} refresh={refresh} />
        )}
      </Box>
    </div>
  );
};

export default TaskListCard;
