import { ApiCall } from "../../helper";
import { getAdvocate } from "../../helper/API/advocate";
import { getProcessGaits } from "../../helper/API/process";
import { GET_GAITS_LIST, GAITS_PROCESSING, STOP_GAITS_PROCESSING, GET_GAITS_ADMENTOS } from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setGaitsList = payload => {
  // console.log("payload ser", payload);
  return {
    type: GET_GAITS_LIST,
    payload,
  };
};

function reverse(array) {
  var output = [];
  while (array.length) {
    output.push(array.pop());
  }

  return output;
}

export const gaitsList = payload => dispatch => {
  return new Promise((resolve, reject) => {
    // console.log("payload", payload);
    dispatch({ type: GAITS_PROCESSING });
    getProcessGaits(payload)
      .then(gaits => {
        // console.log("gaits::::::::", gaits);
        if (gaits.data.gaits) {
          dispatch({
            type: GET_GAITS_LIST,
            payload: reverse(gaits.data.gaits),
          });
        } else {
          dispatch({
            type: GET_GAITS_LIST,
            payload: [],
          });
        }

        dispatch({
          type: GET_GAITS_ADMENTOS,
          payload: gaits.data,
        });
        resolve(gaits);
      })
      .catch(error => {
        dispatch({ type: STOP_GAITS_PROCESSING });
        // console.log("error in login action", error.message);
      });
  });
};
