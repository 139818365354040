import React from "react";
import { Typography, Box, Grid, CardContent, Card, Divider, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

const useStyles = makeStyles({
  card: {},
  head: {
    fontSize: 25,
    fontWeight: 500,
  },
  desc: {
    fontSize: 19,
    fontWeight: 400,
    color: "#000000",
    opacity: 0.5,
  },
  cardHead: {
    fontSize: 22,
    fontWeight: 400,
  },
  descCard: {
    fontSize: 12.5,
    fontWeight: 400,
    color: "#000000",
    opacity: 0.5,
  },
  span: {
    marginLeft: 8,
    color: "#7F56DA",
    fontSize: 13,
    fontWeight: 500,
    background: "#F8F5FE",
    borderRadius: "83.9286px",
    padding: 7,
  },
  tag: {
    fontSize: "33px !important",
    fontWeight: "400 !important",
  },

  content: {
    display: "flex",
    flexDirection: "column",
  },
  content1: {
    border: "0.9px solid #EEEFF3",
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    borderRadius: 5,
  },
  checkLabel: {
    color: "#4B3976",
    fontSize: 18,
  },
  descCard1: {
    fontSize: 12.5,
    fontWeight: 400,
    color: "#000000",
    opacity: 0.7,
  },
  totalLabel: {
    fontSize: "22 !important",
    fontWeight: "600 !important",
  },
  total: {
    fontSize: "30px !important",
    fontWeight: "600 !important",
  },
  orderCard: {
    minHeight: 800,
  },
});

const LockImg = styled("img")({
  maxWidth: 18,
  maxHeight: 21,
  marginLeft: 20,
  marginTop: 8,
});

const Checkout = () => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const listData = ["Mensal", "Anual"];

  return (
    <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
      <Box my={5}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={6} md={6}>
            <Box>
              <Typography className={classes.head}>Checkout</Typography>
              <Typography className={classes.desc}>Preencha seus dados de compra</Typography>
            </Box>
            <Typography variant="body1" className={classes.cardHead} my={2}>
              Período de pagamento
            </Typography>
            {/* <Card variant="elevation" className={classes.card}>
              <CardContent className={classes.content}>
                <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} />
                <Typography variant="body1" className={classes.checkLabel} my={3}>
                  Monthly
                </Typography>
              </CardContent>
            </Card> */}

            <List dense sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
              {listData.map((obj, i) => {
                const labelId = `checkbox-list-secondary-label-${i}`;
                return (
                  <Box display={"flex"} sx={{ border: checked.indexOf(i) !== -1 ? "1px solid #7F56DA" : "none" }}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      onChange={handleToggle(i)}
                      checked={checked.indexOf(i) !== -1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                    <ListItem key={i} disablePadding>
                      <ListItemButton>
                        <ListItemText className={classes.checkLabel} id={labelId} primary={obj} />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                );
              })}
            </List>

            <Typography variant="body1" className={classes.cardHead} my={3}>
              Pagamento <LockImg alt="lock" src="/assets/images/lock.png" />
            </Typography>

            <Card variant="elevation" className={classes.card} sx={{ maxWidth: 500 }}>
              <CardContent className={classes.content}>
                <Box>
                  <Typography variant="body1">Nome no cartão</Typography>
                  <FormControl sx={{ minWidth: "46ch" }}>
                    <OutlinedInput placeholder="Insira o nome aqui" />
                  </FormControl>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} my={2}>
                  <Box>
                    <Typography variant="body1">Data de val.</Typography>
                    <FormControl sx={{ minWidth: "10ch" }}>
                      <OutlinedInput placeholder="08/2024" />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography variant="body1">CVV</Typography>
                    <FormControl sx={{ minWidth: "10ch" }}>
                      <OutlinedInput placeholder="cvv" />
                    </FormControl>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="body1">Número do cartão</Typography>
                  <FormControl
                    sx={{
                      minWidth: "46ch",
                    }}>
                    <OutlinedInput
                      placeholder="Enter card number"
                      sx={{
                        background: "url(/assets/images/masterCard.png) no-repeat scroll 7px 7px",
                        paddingLeft: 8,
                        backgroundSize: 60,
                      }}
                    />
                  </FormControl>
                </Box>
                <Box my={2}>
                  <Typography variant="body1">E-mail</Typography>
                  <FormControl sx={{ minWidth: "46ch" }}>
                    <OutlinedInput placeholder="Digite o e-mail" />
                  </FormControl>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2} sm={6} md={6}>
            <Card variant="elevation" className={classes.orderCard}>
              <CardContent>
                <Typography variant="body1" className={classes.cardHead} my={4}>
                  Resumo do pedido
                </Typography>
                <Box display={"flex"} justifyContent={"space-between"} my={1}>
                  <Typography variant="body1" className={classes.descCard1}>
                    Seu plano: <span style={{ fontWeight: 600 }}>Premium</span>
                  </Typography>
                  <Typography variant="body1" className={classes.descCard1}>
                    R$49
                  </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} my={1}>
                  <Typography variant="body1" className={classes.descCard1}>
                    Período
                  </Typography>
                  <Typography variant="body1" className={classes.descCard1}>
                    Mensal (R$ 49,99)
                  </Typography>
                </Box>
                <FormControl sx={{ minWidth: "100%", margin: "24px 0" }}>
                  <OutlinedInput placeholder="Código de desconto" />
                </FormControl>

                <Divider />

                <Box display={"flex"} justifyContent={"space-between"} my={2}>
                  <Typography variant="body1" className={classes.descCard1}>
                    Subtotal
                  </Typography>
                  <Typography variant="body1" className={classes.descCard1}>
                    R$49.99
                  </Typography>
                </Box>

                <Box display={"flex"} justifyContent={"space-between"} my={2}>
                  <Typography variant="body1" className={classes.descCard1}>
                    Desconto
                  </Typography>
                  <Typography variant="body1" className={classes.descCard1}>
                    0.00
                  </Typography>
                </Box>

                <Divider />

                <Box display={"flex"} justifyContent={"space-between"} my={4}>
                  <Typography variant="body1" className={classes.totalLabel}>
                    Total:
                  </Typography>
                  <Typography className={classes.total}>R$49.99</Typography>
                </Box>

                <Button
                  sx={{ background: "#7C51DB", fontSize: 17, fontWeight: 600, textTransform: "capitalize", width: "100%" }}
                  variant="contained">
                  Pague agora
                </Button>
                <Typography textAlign={"center"} variant="body1" className={classes.descCard} my={3}>
                  <span style={{ margin: "5px 10px" }}>
                    <LockImg alt="lock" src="/assets/images/lock.png" />{" "}
                  </span>{" "}
                  Os pagamentos são seguros
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Checkout;
