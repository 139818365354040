import {
  ADD_CATEGORY,
  GET_CATEGORY,
  ADD_ADVOCATE_TASK,
  GET_ADVOCATE_TASK,
  DONE_ADVOCATE_TASK,
  DELETE_ADVOCATE_TASK,
  EDIT_ADVOCATE_TASK,
  GET_CATEGORY_COLOR,
  GET_ADVOCATE_CALENDAR_TASK,
} from "../url";
import { AfterAuthApi } from "..";

export const addTaskCategory = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_CATEGORY, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getTaskCategory = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_CATEGORY, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

// TASK ADD DELETE EDIT GET

export const addAdvocateTask = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_ADVOCATE_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getAdvocateTask = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ADVOCATE_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getTaskCalendar = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ADVOCATE_CALENDAR_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const taskDone = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DONE_ADVOCATE_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const editAdvocateTask = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_ADVOCATE_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteAdvocateTask = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_ADVOCATE_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getTaskCategoryColor = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_CATEGORY_COLOR, "get")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
