import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Card, CardContent, Grid } from "@mui/material";
import TarefaCard from "../components/Tarefa/TarefaCard";
import { useLocation, useNavigate } from "react-router-dom";
import AndamentosTab from "../components/Gaits/AndamentosTab";
import EquipeTab from "../components/Gaits/EquipeTab";
import TarefaTab from "../components/Gaits/TarefaTab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    marginBottom: "3rem",
    // maxWidth: 500,
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,
      //   bottom: "4px",
      //   left: "6px !important",
      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
  },
  actionContainer: {
    background: "#D6D9FF",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
    // padding: "8px 15px",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "26px",
  },
  subtitle: {
    color: "#757575",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
    margin: "0 !important",
  },
  card: {
    marginTop: "2rem",
    marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
  opinion: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "22px",
    // color: "red"
  },
});
const Andamentos = ({ socket }) => {
  const classes = useStyles();
  const params = useLocation();

  const [value, setValue] = React.useState(params?.state?.value ? params?.state?.value : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log("params gaits value", params);
  return (
    <div>
      <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Paper square className={classes.root} py={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example">
            <Tab label="Andamentos" />
            <Tab label="Equipe" />
            <Tab label="Tarefa" />
          </Tabs>
        </Paper>
        {value === 0 && <AndamentosTab classes={classes} />}
        {value === 1 && <EquipeTab classes={classes} socket={socket} />}
        {value === 2 && <TarefaTab classes={classes} />}
      </Box>
    </div>
  );
};

export default Andamentos;
