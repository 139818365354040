import { GET_LOGIN_RESPONSE } from "./type";

const defaultReducer = {
  data: null,
  processing: false,
};

const loginPlanResponseReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_LOGIN_RESPONSE:
      return {
        ...state,
        processing: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default loginPlanResponseReducer;
