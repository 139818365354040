import {
  GET_PAYMENTTASK_NAMES,
  FINANCE_DASHBOARD_DATA,
  PAYMENT_TASK_GOALS,
  GET_PAYMENT_TASK_GOALS,
  FINANCE_DASHBOARD_TABLE,
  GET_PAYMENT_GRAPHIC_LIST,
  GET_PAYMENT_CHART_DATA,
  GET_PAYMENT_CHART_CARD,
  GET_TRANSACTION_TYPE,
  GET_TRANSACTION_OFFICES,
  ADD_PAYMENT_USER_TYPE,
  ADD_PAYMENT_OFFICE,
  ADD_PAYMENT_NOTE,
  FILTER_PAYMENT_NOTE,
} from "../url";
import { AfterAuthApi } from "..";

export const getPaymentTaskNames = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PAYMENTTASK_NAMES, "get")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const financeDashboardData = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(FINANCE_DASHBOARD_DATA, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const paymentTaskGoals = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(PAYMENT_TASK_GOALS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getPaymentTaskGoals = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PAYMENT_TASK_GOALS, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getFinanceDashboardList = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(FINANCE_DASHBOARD_TABLE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getChartGraphicCardBottom = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PAYMENT_GRAPHIC_LIST, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getChartData = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PAYMENT_CHART_DATA, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getChartCards = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PAYMENT_CHART_CARD, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getTransactionType = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_TRANSACTION_TYPE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getTransactionOffices = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_TRANSACTION_OFFICES, "get")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addPaymentUserType = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_PAYMENT_USER_TYPE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addPaymentUserOffice = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_PAYMENT_OFFICE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addPaymentNote = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_PAYMENT_NOTE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const filterPaymentNote = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(FILTER_PAYMENT_NOTE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
