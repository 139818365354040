import React from "react";
import { Box, Typography } from "@mui/material";

const Chats = ({ sx, chatData, onClick }) => {
  // console.log("chatData", chatData);
  return (
    <>
      <Box sx={sx} onClick={() => onClick(chatData.senderId)}>
        <div style={{ padding: "0.5rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div
            style={{
              marginRight: "1rem",
              // border: "1px solid black",
              height: 50,
              width: 50,
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img
              src={chatData.avatar ? chatData.avatar : "/temp/user.png"}
              style={{ height: "100%", width: "100%", borderRadius: "15px" }}
            />
          </div>

          <div>
            <Typography sx={{ fontWeight: 400, fontFamily: "Poppins", fontSize: "15px" }}>{chatData.sender}</Typography>
            <Typography sx={{ color: "#B5B8CB", fontWeight: 400, fontFamily: "Poppins" }}>{chatData.subTitle}</Typography>
          </div>
        </div>
        <div style={{ padding: "0.5rem" }}>
          <div style={{ color: "#B5B8CB", fontWeight: 400, fontFamily: "Poppins" }}>{chatData.msgTime}</div>

          {chatData.msgCount > 0 && (
            <div>
              <div
                style={{
                  border: "2px solid #8E91F4",
                  backgroundColor: "#8E91F4",
                  borderRadius: "35%",
                  width: 20,
                  height: 20,
                  textAlign: "center",
                  color: "#ffffff",
                  marginTop: "0.5rem",
                }}>
                {chatData.msgCount}
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default Chats;
