import React from "react";
import { Avatar, AvatarGroup, Box, Button, Card, CardContent, Typography } from "@mui/material";

const ProcessDetailCard = ({ classes, singleProcessData }) => {
  return (
    <div>
      <Card
        variant="elevation"
        className={classes.card}
        sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
        <CardContent sx={{ position: "relative" }}>
          <Box>
            <Typography sx={{ fontWeight: 600 }}>Tribunal:</Typography>
            <Typography>{singleProcessData?.tribunal}</Typography>
          </Box>
        </CardContent>
      </Card>
      {singleProcessData?.juiz && (
        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "30px", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <>
                <Typography sx={{ fontWeight: 600 }}>Juízo:</Typography>
                <Typography>{singleProcessData?.juiz}</Typography>
              </>
            </Box>
          </CardContent>
        </Card>
      )}
      {singleProcessData?.assunto && (
        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px6", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>Assunto:</Typography>
              <Typography>{singleProcessData?.assunto}</Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ProcessDetailCard;
