import React from "react";
import { TextField } from "@mui/material";
const StyledTextField = ({ inputProps, label, placeholder, onChange, name, defaultValue, value, disabled }) => {
  return (
    <div>
      <TextField
        inputProps={inputProps}
        name={name}
        label={label}
        variant="outlined"
        defaultValue={defaultValue}
        onChange={onChange}
        fullWidth
        placeholder={placeholder}
        value={value}
        // autoFocus
        disabled={disabled}
      />
    </div>
  );
};

export default StyledTextField;
