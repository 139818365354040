import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const CustomProgressBar = ({ data }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: data?.width ? data.width : "100%",
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `${data.lightColor}`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: `${data.darkColor}`,
    },
  }));

  return <BorderLinearProgress variant="determinate" value={data.value} />;
};

export default CustomProgressBar;
