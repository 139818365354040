import { GET_USER_PROFILE_SUCCESSFUL, GET_USER_PROFILE_PROCESSING, STOP_GET_USER_PROFILE_PROCESSING, GET_ALL_PROFILE } from "./type";

const defaultReducer = {
  profile: null,
  processing: false,
  allData: null,
};

const getProfileReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        processing: false,
        profile: action.payload,
      };
    case GET_ALL_PROFILE:
      return {
        ...state,
        processing: false,
        allData: action.payload,
      };

    case GET_USER_PROFILE_PROCESSING:
      return {
        ...state,
        processing: true,
        profile: null,
      };

    case STOP_GET_USER_PROFILE_PROCESSING:
      return {
        ...state,
        processing: false,
        profile: null,
      };

    default:
      return state;
  }
};

export default getProfileReducer;
