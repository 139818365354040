import React, { useEffect } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import DropdownButton from "../components/Processos/DropdownButton";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, Grid, InputAdornment, OutlinedInput, TextField } from "@mui/material";

import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import SwitchUnstyled, { switchUnstyledClasses } from "@mui/base/SwitchUnstyled";
import { Container } from "@mui/system";
import StyledTextField from "../components/Inputs/StyledTextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getOccupationType } from "../helper/API/office";
import OfficeDataSelect from "../components/office/OfficeDataSelect";
import { addAdvocate, editAdvocate } from "../helper/API/advocate";
import { toast } from "react-toastify";

const currencies = [
  {
    value: "skype",
    label: "Skype",
  },
];
const Reuniaos = [
  {
    value: "Dr. Júlio Rocha",
    label: "Dr. Júlio Rocha",
  },
  {
    value: "Dr. Paulo Roberto",
    label: "Dr. Paulo Roberto",
  },
  {
    value: "Dra. Renata Mello",
    label: "Dra. Renata Mello",
  },
];

const blue = {
  500: "#5031A9",
};

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 1,
    // maxWidth: 500,
    // "& .MuiButtonBase-root": {
    //     background: "red"
    // },
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
  yourBtn: {
    // position: "relative",
    // top: "150px",
    // width: "150px",
    padding: "5px",
    border: "1px dashed #BBB",
    textAlign: "center",
    backgroundColor: "#DDD",
    cursor: "pointer",
    marginLeft: "10px",
  },
  imgPreview: {
    borderRadius: "50%",
    height: "70px",
    width: "70px",
  },
});

const AdicionarAdvogado = () => {
  const params = useLocation();
  const editId = params?.state?.id;
  const editAdvocateData = params?.state?.advocate;
  // console.log("editAdvocateData", editAdvocateData);
  const editSocialLink = editAdvocateData?.socialLink?.map((obj, indx) => obj.link);
  const hiddenFileInput = React.useRef(editAdvocateData ? editAdvocateData.avatar : null);
  const [loading, setLoading] = React.useState(false);
  const [images, setImages] = React.useState("");
  const [imagePreview, setImagePreview] = React.useState(editAdvocateData ? editAdvocateData.avatar : null);
  const [occupationType, setOccupationType] = React.useState(null);
  const [occupationId, setOccupationId] = React.useState(editAdvocateData ? editAdvocateData?.occupationArea?.id : "");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectVal, setSelectVal] = React.useState(editAdvocateData ? editAdvocateData?.occupationArea?.label : "");
  const [formValues, setFormValues] = React.useState(editAdvocateData ? editSocialLink : [""]);
  const [gender, setGender] = React.useState(editAdvocateData ? editAdvocateData.gender : "");
  const [formField, setFormFields] = React.useState({
    ...(editId && { id: editId }),
    name: editAdvocateData ? editAdvocateData.name : "",
    email: editAdvocateData ? editAdvocateData.email : "",
    telephone: editAdvocateData ? editAdvocateData.telephone : "",
    oabCode: editAdvocateData ? editAdvocateData.oabCode : "",
    ufCode: editAdvocateData ? editAdvocateData.ufCode : "",
    selfDesc: editAdvocateData ? editAdvocateData.selfDesc : "",
  });
  useEffect(() => {
    getOccupationType().then(res => {
      if (res.data.success) {
        setOccupationType(res?.data?.data);

        //  const selectOccupation = res?.data?.data?.filter(obj => obj?.id === getOfficeData?.occupationArea)[0];
        //  setSelectVal(selectOccupation?.label);
      }
    });
  }, []);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickDropDown = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = event => {
    const fileUploaded = event.target.files[0];
    const maxSize = 1024 * 1024; // 1MB in bytes

    if (fileUploaded.size < maxSize) {
      if (event.target.files[0]) {
        setImages(event.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImagePreview(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      toast.error("Selecione uma imagem com menos de 1 MB");
    }
  };

  const handleSave = () => {
    navigate("/home/adicionar-nota");
  };

  let handleTypeChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, ""]);
  };

  let removeFormFields = i => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleChangeForm = e => {
    setFormFields({
      ...formField,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = e => {
    setOccupationId(e.target.value);
  };
  const handleGenderChange = e => {
    setGender(e.target.value);
  };

  const onSubmitAdvocate = () => {
    const newFormvalues = formValues.filter(val => {
      if (Object.keys(val).length !== 0) {
        return val;
      }
    });
    setLoading(true);
    if (!formField.name) {
      setLoading(false);
      toast.error("por favor digite o nome");
    } else if (!formField.telephone) {
      setLoading(false);
      toast.error("por favor digite o telefone");
    } else if (!formField.email) {
      setLoading(false);
      toast.error("por favor digite E-mail");
    } else if (!formField.oabCode) {
      setLoading(false);
      toast.error("por favor digite o codigo oab");
    } else if (!formField.ufCode) {
      setLoading(false);
      toast.error("por favor insira o código uf");
    } else if (!occupationId) {
      setLoading(false);
      toast.error("por favor insira a área de ocupação");
    } else if (!newFormvalues.length) {
      setLoading(false);
      toast.error("por favor insira o link social");
    } else if (!formField.selfDesc) {
      setLoading(false);
      toast.error("por favor insira a autodescrição");
    } else if (!gender) {
      setLoading(false);
      toast.error("por favor selecione um gênero");
    } else if (!editId) {
      setLoading(true);
      addAdvocate(formField, occupationId, newFormvalues, images, gender).then(res => {
        if (res.data.success) {
          // console.log("res add", res);
          toast.success(res.data.message);
          navigate("/home/office");
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      });
    } else {
      setLoading(true);
      editAdvocate(formField, occupationId, newFormvalues, images, gender).then(res => {
        // console.log("res edit", res);
        if (res.success) {
          setLoading(false);
          navigate("/home/office");
          toast.success(res.message);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      });
    }
  };
  // console.log("gender", editAdvocateData?.gender);
  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "end", marginBottom: "2rem" }}>
          {/* <Typography >Voltar</Typography> */}
          <Button
            variant="contained"
            onClick={onSubmitAdvocate}
            disabled={loading}
            sx={{ fontWeight: 600, fontFamily: "Poppins", backgroundColor: "#734699" }}
            // onClick={handleSave}
          >
            Salvar
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  marginLeft: "12px",
                }}
              />
            )}
          </Button>
        </Box>

        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <Typography variant="h4">{editId ? "Editar advogado" : "Adicionar advogado"}</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <StyledTextField
                      name="name"
                      label="Nome"
                      defaultValue={formField.name}
                      onChange={handleChangeForm}
                      fullWidth
                      placeholder="Digite o nome"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      name="telephone"
                      onChange={handleChangeForm}
                      defaultValue={formField.telephone}
                      fullWidth
                      label="Telefone"
                      variant="outlined"
                      placeholder="Digite o telefone"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      id="email-basic"
                      label="E-mail"
                      name="email"
                      variant="outlined"
                      placeholder="Digite o E-mail"
                      onChange={handleChangeForm}
                      defaultValue={formField.email}
                      fullWidth
                      disabled={editAdvocateData && true}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      name="oabCode"
                      id="oabCode-basic"
                      label="Código OAB"
                      variant="outlined"
                      onChange={handleChangeForm}
                      fullWidth
                      placeholder="Digite aqui"
                      defaultValue={formField.oabCode}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      name="ufCode"
                      id="outlined-basic"
                      label="UF"
                      variant="outlined"
                      onChange={handleChangeForm}
                      fullWidth
                      placeholder="Digite aqui"
                      defaultValue={formField.ufCode}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="standard-select-currency"
                        label="Selecione uma área"
                        select
                        name="userType"
                        variant="outlined"
                        defaultValue={occupationId}
                        onChange={handleSelectChange}>
                        {/* {editId && (
                          <MenuItem value={occupationId} disabled>
                            {selectVal}
                          </MenuItem>
                        )} */}
                        {occupationType?.map((obj, indx) => (
                          <MenuItem value={obj.id}>{obj.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="standard-select-currency"
                        label="Gênero"
                        select
                        name="gender"
                        variant="outlined"
                        defaultValue={gender}
                        onChange={handleGenderChange}>
                        {/* {editId && (
                          <MenuItem value={occupationId} disabled>
                            {selectVal}
                          </MenuItem>
                        )} */}

                        <MenuItem value="male">Masculino</MenuItem>
                        <MenuItem value="female">Feminino</MenuItem>
                        <MenuItem value="other">Não Binário</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  {/* <Grid item md={12}>
                    <StyledTextField
                      id="outlined-basic"
                      label="Website"
                      variant="outlined"
                      fullWidth
                      // onChange={handleChangeForm}
                      placeholder="Insira o link do website"
                    />
                  </Grid> */}

                  {formValues.map((element, index) => (
                    <Grid item md={6} sx={{ display: "inline-block", position: "relative" }} key={index}>
                      <StyledTextField
                        label="Redes sociais"
                        defaultValue={formValues[index]}
                        placeholder="Insira aqui"
                        onChange={e => handleTypeChange(index, e)}
                        sx={{ width: "100%" }}
                      />
                      {index ? (
                        // <Button variant="contained">
                        <CancelIcon
                          sx={{ fontWeight: 600, fontFamily: "Poppins", mt: 1, position: "absolute", right: "1%", top: "33%" }}
                          onClick={() => removeFormFields(index)}
                        />
                      ) : // </Button>
                      null}
                    </Grid>
                  ))}
                  <Grid item md={2}>
                    <Button variant="contained" sx={{ fontWeight: 600, fontFamily: "Poppins" }} onClick={() => addFormFields()}>
                      <AddCircleIcon sx={{ pr: 1 }} /> Adicionar
                    </Button>
                  </Grid>
                  <Grid item md={4} display="flex" justifyContent="right">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Img src={imagePreview ? imagePreview : "/assets/images/uploadImg.png"} className={classes.imgPreview} />
                        <div className={classes.yourBtn} onClick={handleClick}>
                          Adicionar foto
                        </div>

                        <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                          <input
                            id="upfile"
                            type="file"
                            accept={"image/*"}
                            ref={hiddenFileInput}
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <TextareaAutosize
                      label="Mini currículo"
                      placeholder="Escreva uma breve descrição sobre você e suas conquistas, se quiser indicar cursos e faculdades sinta-se a vontade."
                      aria-label="minimum height"
                      name="selfDesc"
                      minRows={10}
                      // maxCol={8}
                      onChange={handleChangeForm}
                      defaultValue={formField.selfDesc}
                      variant="outlined"
                      style={{ border: "2px solid #DBD7F4", borderRadius: "13px", width: "100%", resize: "none", margin: 0, padding: 0 }}
                    />
                  </Grid>
                  {/* <Grid item md={6}>
                    <StyledTextField
                      id="outlined-basic"
                      label="Valor"
                      name="selfDesc"
                      variant="outlined"
                      // onChange={handleChangeForm}
                      fullWidth
                      placeholder="Digite aqui o valor"
                    />
                  </Grid> */}
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default AdicionarAdvogado;
