import { GET_CLIENT_LIST, STOP_CLIENT_PROCESSING, CLIENT_PROCESSING } from "./type";

const defaultReducer = {
  clientList: [],
  processing: false,
};

const clientListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_CLIENT_LIST:
      return {
        ...state,
        processing: false,

        clientList: action.payload,
      };

    case CLIENT_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_CLIENT_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default clientListReducer;
