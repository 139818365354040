import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import StyledButton from "../components/StyledButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styled from "@emotion/styled";
import { forgotPassword, verifyForgotPassword } from "../helper/API/auth";
import { toast } from "react-toastify";
const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  position: "relative",
  left: "-10px",
  top: "-20px",
});

const VerifyAndUpdatePassword = () => {
  const { login } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const submitData = {
      email: JSON.parse(localStorage.getItem("email")),
      forgotOtp: Number(data.get("forgotOtp")),
      password: data.get("password"),
      confirmPassword: data.get("confirmPassword"),
    };
    verifyForgotPassword(submitData).then(res => {
      if (res.success) {
        toast.success(res.message);
        navigate("/");
      } else {
        toast.error(res.message);
      }
    });
    // console.log("submitData", submitData);

    // login();
  };
  const resendOTP = () => {
    // console.log("clicked resend otp");
    const resendOtp = { email: JSON.parse(localStorage.getItem("email")) };
    forgotPassword(resendOtp).then(res => {
      // console.log("res", res);
      if (res.success) {
        // toast.success(res.message);
        toast.success("OTP enviar para o e-mail cadastrado");
        //  navigate("/verify-password");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: 5, py: 5, px: 5, backgroundColor: "#fff" }}>
        <Link to="/forgot-password">
          <Img alt="complex" src="/assets/images/backbtn.png" sx={{ height: 150 }} />
        </Link>
        <Container>
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography component="h2" variant="h5" sx={{ mb: 3 }}>
              Esqueceu sua senha
            </Typography>
            <Typography component="h6" variant="h5" sx={{ color: "#92929D", fontSize: "16px", textAlign: "center", mb: 3 }}>
              Digite o CÓDIGO que é recebido em seu e-mail
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                placeholder="Digite seu CÓDIGO"
                required
                fullWidth
                id="otp"
                label="CÓDIGO"
                name="forgotOtp"
                autoComplete="otp"
                autoFocus
              />
              <TextField
                margin="normal"
                placeholder="Digite sua senha"
                required
                fullWidth
                id="password"
                label="Senha"
                name="password"
                autoComplete="password"
                autoFocus
              />
              <TextField
                margin="normal"
                placeholder="Digite sua senha de confirmação"
                required
                fullWidth
                id="confirmPassword"
                label="Confirme a Senha"
                name="confirmPassword"
                autoComplete="confirmPassword"
                autoFocus
              />
              <Grid container sx={{ display: "flex", justifyContent: "end", marginBottom: "0.5rem" }}>
                <Grid item>
                  {/* <RouterLink to="/forgot-password"> */}
                  <a style={{ color: "#9A9AB0", textDecoration: "none", cursor: "pointer" }} onClick={resendOTP}>
                    Reenviar OTP
                  </a>
                  {/* </RouterLink> */}
                </Grid>
              </Grid>
              <StyledButton type={"submit"} fullWidth variant={"contained"} btnText={"Recuperar Senha"} sx={{ mt: 3, mb: 2 }} />
            </Box>
          </Box>
        </Container>
      </Container>
    </>
  );
};

export default VerifyAndUpdatePassword;
