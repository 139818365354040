import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Switch,
  Button,
  Card,
  CardContent,
  Checkbox,
  MenuItem,
  TextareaAutosize,
  Typography,
  FormLabel,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import DropdownButton from "../components/Processos/DropdownButton";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, Grid, InputAdornment, OutlinedInput, TextField } from "@mui/material";

import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import SwitchUnstyled, { switchUnstyledClasses } from "@mui/base/SwitchUnstyled";
import { Container } from "@mui/system";
import StyledTextField from "../components/Inputs/StyledTextField";
import StyledOutlinedInput from "../components/Inputs/StyledOutlinedInput";
import { getInviteProcessUser } from "../helper/API/process";
import { addAdvocateTask, editAdvocateTask } from "../helper/API/task";
import { toast } from "react-toastify";
import moment from "moment";
import DatePickerTranslated from "../components/DatePickerTranslated";

const meeting = [
  {
    value: "Skype",
    label: "Skype",
  },
  {
    value: "Google Meet",
    label: "GoogleMeet",
  },
];
const meetingTime = [
  {
    value: "Reuniao",
    label: "Reuniao",
  },
  {
    value: "Reuniao1",
    label: "Reuniao1",
  },
];

const blue = {
  500: "#5031A9",
};

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const Root = styled("span")(
  ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
      border-radius: 10px;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 3px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;
      transition: all 200ms ease;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: ${grey[500]};
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 22px;
        top: 3px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background: ${blue[500]};
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `,
);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 1,
    // maxWidth: 500,
    // "& .MuiButtonBase-root": {
    //     background: "red"
    // },
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

function Information() {
  const params = useLocation();

  const editData = params?.state?.data;
  const inviteMemberIdArray = editData?.inviteMembers?.map((obj, indx) => obj.id);
  const editId = params?.state?.id;
  const [meetingType, setMeetingType] = useState(editData?.meetingType);
  const [meetingAt, setmeetingAt] = useState(editData?.meetingAt);
  const [inviteMemberData, setInviteMemberData] = useState([]);
  const [inviteMember, setInviteMember] = useState(editData ? editData?.inviteMembers : []);
  const classes = useStyles();
  const navigate = useNavigate();
  const [datePickerValue, setDatePickerValue] = React.useState(editId ? moment(editData.date).format("YYYY-MM-DD") : "");
  // console.log("datePickerValue", datePickerValue);

  // localStorage.setItem("processId", params.state.process.id);
  const inviteMembersId = JSON.parse(localStorage.getItem("process"));
  // console.log("inviteMembersId", inviteMember);

  const [formValues, setFormValues] = useState({
    title: editId ? editData.title : "",
    startTime: editId ? editData.startTime : "",
    endTime: editId ? editData.endTime : "",
    description: editId ? editData.description : "",
    isAlert: editId ? editData.isAlert : "",
  });

  useEffect(() => {
    const submitData = { id: inviteMembersId.id };
    getInviteProcessUser(submitData).then(res => {
      setInviteMemberData(res.data);
      // console.log("process Invited user Get api ", res);
    });
  }, []);

  const handleChangeMeeting = event => {
    setMeetingType(event.target.value);
  };

  const handleChangeMeetingAt = event => {
    setmeetingAt(event.target.value);
  };

  const handleFormChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSwitch = e => {
    setFormValues({ ...formValues, isAlert: e.target.checked });
  };

  const handleChecke = (e, data, index) => {
    if (!editData) {
      const permissionArr = inviteMember.indexOf(data);
      if (permissionArr === -1) {
        setInviteMember([...inviteMember, data]);
      } else {
        const permissionRemove = inviteMember;
        const ab = permissionRemove.splice(permissionArr, 1);
        setInviteMember(permissionRemove);
      }
    } else {
      const permissionId = inviteMember.find(obj => obj.id == data.id);
      if (!permissionId) {
        setInviteMember([...inviteMember, data]);
      } else {
        const permissionId = inviteMember.filter(obj => obj.id !== data.id);

        setInviteMember(permissionId);
      }
    }
  };
  // console.log("data checked", inviteMember);

  // console.log("params Information", params);

  const handleGaits = () => {
    navigate("/home/gaits");
  };

  const onSubmitProcessTask = () => {
    const submitData = {
      ...(editId && { id: editId }),
      processId: inviteMembersId.id,
      ...formValues,
      meetingType,
      meetingAt,
      inviteMembers: inviteMember,
      date: datePickerValue,
    };
    // console.log("submitData", submitData);
    if (!editId) {
      addAdvocateTask(submitData).then(res => {
        if (res.success) {
          toast.success(res.message);
          navigate("/home/gaits", { state: { value: 2 } });
        } else {
          toast.error(res.message);
        }
      });
    } else {
      // console.log("submitData::::", submitData);
      editAdvocateTask(submitData).then(res => {
        if (res.success) {
          toast.success(res.message);
          navigate("/home/gaits", { state: { value: 2 } });
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const handleBack = () => {
    navigate("/home/gaits", { state: { value: 2 } });
  };

  const inviteId = inviteMember.map((obj, index) => obj.id);
  // console.log("inviteMember ::", inviteMember);
  // console.log("inviteMemberData", inviteMemberData);

  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "space-between", marginBottom: "2rem" }}>
          <Typography sx={{ cursor: "pointer", fontFamily: "Poppins", fontWeight: 400 }} onClick={handleBack}>
            Voltar
          </Typography>
          <Button variant="contained" sx={{ fontWeight: 600, fontFamily: "Poppins" }} onClick={onSubmitProcessTask}>
            Salvar
          </Button>
        </Box>

        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 500, fontFamily: "Poppins" }}>
                Informações
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={12} sx={{ mt: 3 }}>
                    <StyledOutlinedInput placeholder="Título" defaultValue={formValues.title} name="title" onChange={handleFormChange} />
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Hora de início</FormLabel>
                      <OutlinedInput
                        id="startTime"
                        name="startTime"
                        type="time"
                        placeholder="Hora de início"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleFormChange}
                        defaultValue={formValues.startTime}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Fim do tempo</FormLabel>
                      <OutlinedInput
                        id="endTime"
                        type="time"
                        name="endTime"
                        placeholder="Fim do tempo"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleFormChange}
                        defaultValue={formValues.endTime}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sx={{ mt: 3 }}>
                    {/* <FormControl variant="outlined" fullWidth>
                      <FormLabel>Encontro</FormLabel>
                      <OutlinedInput
                        value={formValues.date}
                        id="date"
                        name="date"
                        type="date"
                        placeholder="Encontro"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleFormChange}
                        defaultValue={formValues.date}
                      />
                    </FormControl> */}
                    <DatePickerTranslated setDatePickerValue={setDatePickerValue} datePickerValue={datePickerValue} />
                  </Grid>

                  {/* Skype */}
                  {/* <Grid item md={8} sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Presencial"
                      name="meetingType"
                      defaultValue={meetingType}
                      onChange={handleChangeMeeting}
                      fullWidth>
                      {meeting.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Reunião em"
                      // name="meetingAt"
                      value={meetingAt}
                      onChange={handleChangeMeetingAt}
                      fullWidth>
                      {meetingTime.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src="/assets/images/Oval.png" style={{ paddingRight: 5, height: 20 }} />
                            {option.label}
                          </div>
                          <Typography></Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  {/* TextArea */}
                  <Grid item md={12} sx={{ mt: 3 }}>
                    <TextField
                      onChange={handleFormChange}
                      defaultValue={formValues.description}
                      placeholder="Digite a nota aqui..."
                      multiline
                      name="description"
                      rows={10}
                      variant="outlined"
                      sx={{
                        border: "2px solid #DBD7F4",
                        borderRadius: "13px",
                        width: "100%",
                        resize: "none",
                      }}
                    />
                    {/* <textarea
                      aria-label="minimum height"
                      minRows={10}
                      name="description"
                      pl={9}
                      // maxCol={8}
                      variant="outlined"
                      placeholder="Digite a nota aqui..."
                      style={{
                        border: "2px solid #DBD7F4",
                        borderRadius: "13px",
                        width: "100%",
                        resize: "none",
                      }}
                     
                    /> */}
                  </Grid>
                  <Grid item md={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: 400, color: "#222B45", fontWeight: 500, fontFamily: "Poppins" }}>Lembrar</Typography>
                    <Switch name="isAlert" onChange={handleSwitch} defaultChecked={formValues.isAlert} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 500, fontFamily: "Poppins" }}>
            Convidar membros
          </Typography>
          {inviteMemberData?.map((obj, indx) => (
            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", mb: 2 }} key={indx}>
              <Checkbox
                defaultChecked={inviteId && (inviteId.indexOf(obj.id) >= 0 ? true : false)}
                onChange={e => handleChecke(e, obj, indx)}
              />
              <Avatar alt="Remy Sharp" src={obj?.avatar} />
              <Typography sx={{ ml: 1, fontWeight: 500, fontFamily: "Poppins", lineHeight: "20px" }}>{obj?.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}

export default Information;
