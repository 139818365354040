import { ApiCall } from "../../helper";
import { getAdvocate } from "../../helper/API/advocate";
import { GET_ADVOCATE_LIST, STOP_ADVOCATE_PROCESSING, ADVOCATE_PROCESSING } from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setAdvocateList = payload => {
  // console.log("payload ser", payload);
  return {
    type: GET_ADVOCATE_LIST,
    payload,
  };
};

export const advocateList = payload => dispatch => {
  return new Promise((resolve, reject) => {
    // console.log("payload", payload);
    dispatch({ type: ADVOCATE_PROCESSING });
    getAdvocate()
      .then(advocate => {
        // console.log("advocate", advocate);
        dispatch({
          type: GET_ADVOCATE_LIST,
          payload: advocate.data,
        });
        resolve(advocate.data);
      })
      .catch(error => {
        dispatch({ type: STOP_ADVOCATE_PROCESSING });
        // console.log("error in login action", error.message);
      });
  });
};
