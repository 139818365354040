import React from "react";
import { Avatar, AvatarGroup, Box, Button, Card, CardContent, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});
const AuthorCard = ({ classes, handleAuthor, data, singleProcessData, permission }) => {
  let autor = [];
  singleProcessData?.autor?.map(obj => {
    if (obj?.nome) {
      return autor.push(obj.nome);
    }
  });
  return (
    <div>
      <Card
        variant="elevation"
        className={classes.card}
        sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ fontWeight: 600 }}>Autor (a):</Typography>
            <Typography sx={{ fontWeight: 500, display: "inline" }}>
              {autor
                ?.filter(obj => obj != null)
                ?.map((obj, i) => {
                  return (
                    <>
                      <span key={i}>{obj}</span>
                      <br />
                    </>
                  );
                })}
            </Typography>

            <br />
            <Typography sx={{ fontWeight: 500, display: "inline" }}>
              {singleProcessData?.author
                ?.filter(obj => obj != null)
                ?.map((obj, i) => {
                  return (
                    <>
                      <span key={i}>{obj}</span>
                      <br />
                    </>
                  );
                })}
            </Typography>
          </Box>
          {permission?.isOwner && permission?.processMgt && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }} onClick={handleAuthor}>
              <Img
                alt="Delete"
                src="/assets/images/lawyers.png"
                width={50}
                height={50}
                sx={{
                  maxWidth: "30px !important",
                  maxHeight: "30px !important",
                  posotion: "absolute",
                  background: "#fff",
                  color: "red",
                  padding: "12px",
                  borderRadius: "13px",
                }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default AuthorCard;
