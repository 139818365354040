import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import HoverMenu from "./HoverMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";
import CardTemplate from "./CardTemplate";
import { getFinanceDashboardList, getPaymentTaskNames } from "../../helper/API/paymentTaskByAdvocate";
import Loader from "../Loader";
import RecordFound from "../RecordFound/RecordFound";

const Img = styled("img")({
  display: "block",
});

const TableTemplate = ({ setSelectVal, selectVal, loading, setLoading, tableRows, setTableRows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropDownValue, setDropDownValue] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getPaymentTaskNames().then(res => setDropDownValue(res.data));
  }, [selectVal]);

  function createData(name, data, tipo, total) {
    return {
      name,
      data,
      tipo,
      total,
    };
  }

  const handleSelectChange = (e, value) => {
    // console.log("value", value);
  };

  return (
    <div>
      <Container
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          paddingLeft: "unset !important",
          cursor: "pointer",
          width: 282,
          marginLeft: "unset !important",
          position: "relative",
        }}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography sx={{ color: "#464A53", fontWeight: "600", fontFamily: "Poppins" }} variant="h6">
            Ranking Advogados
          </Typography>
          <KeyboardArrowDownIcon />
        </Box>
      </Container>
      {dropDownValue && (
        <HoverMenu
          open={open}
          handleClick={handleClick}
          handleClose={() => setAnchorEl(null)}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          setSelectVal={setSelectVal}
          dropDownValue={dropDownValue}
          handleSelectChange={handleSelectChange}
        />
      )}
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} my={2}>
        <Typography sx={{ color: "#464A53", fontWeight: 600, fontFamily: "Poppins" }} variant="h6">
          Mostrando de 1 a 5
        </Typography>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          {/* <Img
            alt="download"
            src="/assets/images/download.png"
            sx={{
              maxWidth: "60px !important",
              maxHeight: "60px !important",
              cursor: "pointer",
              marginRight: 2,
            }}
          />
          <Img
            alt="download"
            src="/assets/images/print.png"
            sx={{
              maxWidth: "60px !important",
              maxHeight: "60px !important",
              cursor: "pointer",
            }}
          /> */}
        </Box>
      </Box>
      {/* {selectVal === "clientes" ? ( */}
      <>
        {loading ? (
          <Loader />
        ) : tableRows.length ? (
          <TableComponent rows={tableRows} selectVal={selectVal} />
        ) : (
          <RecordFound label="Nenhum registro foi encontrado" />
        )}
        <CardTemplate />
      </>
      {/* ) : (
        <TableComponent rows={tableRows} selectVal={selectVal} />
      )} */}
    </div>
  );
};

export default TableTemplate;
