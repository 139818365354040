import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getValidatingProcess, searchProcessList, registerProcess, deleteValidatingProcess } from "../../helper/API/process"; // Certifique-se de ajustar o caminho conforme necessário
import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "red",
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const ValidatingProcessCard = () => {
  const classes = useStyles();
  const [validatingProcesses, setValidatingProcesses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    getValidatingProcess()
      .then((data) => {
        setValidatingProcesses(data.validatingProcesses || []);
      })
      .catch((error) => {
        console.error("Erro ao buscar processos validando:", error);
      });
  }, [refresh]);

  const handleSearchProcess = (processNumber) => {
    setLoading(true);
    const submitData = { numProcesso: processNumber };
    searchProcessList(submitData)
      .then((res) => {
        if (res.success) {
          const process = res.data[0];
          if (process.status === "VALIDANDO") {
            toast.error("Processo ainda em validação");
            setLoading(false);
          } else if (process.status === "CADASTRADO") {
            registerProcess({ codProcesso: res.data[0].codProcesso.toString() })
              .then(async res => {
                if (res.success) {
                  deleteValidatingProcess(submitData)
                  window.location.reload();
                  toast.success(res.message);
                } else {
                  toast.error(res.message);
                }
              })
          }
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o processo:", error);
        toast.error("Erro ao buscar o processo");
        setLoading(false);
      });
  };

  const handleDeleteProcess = (processNumber) => {
    setLoading(true);
    const submitData = { numProcesso: processNumber };
    deleteValidatingProcess(submitData)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          setRefresh((prevRefresh) => prevRefresh + 1);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Erro ao deletar o processo:", error);
        toast.error("Erro ao deletar o processo");
        setLoading(false);
      });
  };

  return (
    <div>
      <h2>Processos Validando:</h2>
      <ul>
        {validatingProcesses.map((processNumber, index) => (
          <Card
            key={index}
            variant="elevation"
            className={classes.card}
            sx={{
              background: "rgba(236, 163, 177, 0.09)",
              marginBottom: "1rem",
              borderRadius: "30px",
            }}>
            <CardContent>
              <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={""}>
                <Box flex={1} ml={1}>
                  <Typography>
                    <span style={{ fontWeight: 600 }}>Número do processo: </span>
                    <span>{processNumber}</span>
                  </Typography>
                </Box>
                <Box p={0} sx={{ position: "relative", zIndex: 500 }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    cursor={"pointer"}
                    alignItems={"center"}
                    className={classes.actionContainer}>
                    <div
                      style={{
                        posotion: "relative",
                        padding: "5px 15px",
                        borderTopRightRadius: "13px",
                        borderBottomRightRadius: "13px",
                      }}
                      onClick={() => handleSearchProcess(processNumber)}>
                      <Img
                        alt="Atualizar"
                        src="/assets/images/ragister.png"
                        width={50}
                        height={50}
                        sx={{
                          maxWidth: "30px !important",
                          maxHeight: "30px !important",
                          posotion: "absolute",
                          background: "#fff",
                          color: "red",
                          padding: "12px",
                          borderRadius: "13px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        posotion: "relative",
                        padding: "5px 15px",
                        borderTopRightRadius: "13px",
                        borderBottomRightRadius: "13px",
                      }}
                      onClick={() => handleDeleteProcess(processNumber)}>
                      <Img
                        alt="Delete"
                        src="/assets/images/registerDelete.png"
                        width={50}
                        height={50}
                        sx={{
                          maxWidth: "30px !important",
                          maxHeight: "30px !important",
                          posotion: "absolute",
                          background: "#fff",
                          color: "red",
                          padding: "12px",
                          borderRadius: "13px",
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </ul>
    </div>
  );
};

export default ValidatingProcessCard;
