import { ApiCall } from "../../helper";
import { getAdvocate } from "../../helper/API/advocate";
import { GET_LOGIN_RESPONSE } from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setLoginResponse = payload => {
  // console.log("payload ser", payload);
  return {
    type: GET_LOGIN_RESPONSE,
    payload,
  };
};
