// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";

// let firebaseConfig = {
//   apiKey: "AIzaSyCv-cbPDemqNvZyGwLnSd0QTToSjLBog9M",
//   authDomain: "inovajuris-5bde3.firebaseapp.com",
//   databaseURL: "https://inovajuris-5bde3-default-rtdb.firebaseio.com",
//   projectId: "inovajuris-5bde3",
//   storageBucket: "inovajuris-5bde3.appspot.com",
//   messagingSenderId: "619960625495",
//   appId: "1:619960625495:web:35d067bfa957319e823612",
//   measurementId: "G-JSMHLYK76M",
// };

// firebase.initializeApp(firebaseConfig);
// var auth = firebase.auth();
// export { auth, firebase };

import { initializeApp } from "firebase/app";
import { OAuthProvider, getAuth } from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCv-cbPDemqNvZyGwLnSd0QTToSjLBog9M",
  authDomain: "inovajuris-5bde3.firebaseapp.com",
  databaseURL: "https://inovajuris-5bde3-default-rtdb.firebaseio.com",
  projectId: "inovajuris-5bde3",
  storageBucket: "inovajuris-5bde3.appspot.com",
  messagingSenderId: "619960625495",
  appId: "1:619960625495:web:35d067bfa957319e823612",
  measurementId: "G-JSMHLYK76M",
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);

// FACEBOOK LOGIN
export const provider = new FacebookAuthProvider();

// APPLE LOGIN
export const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
