import * as React from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/ru";
import "dayjs/locale/de";
import "dayjs/locale/ar-sa";
import "dayjs/locale/pt";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#21212130 !important",
      },
      "&:hover fieldset": {
        borderColor: "#000 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000 !important",
      },
    },
  },
})(TextField);

export default function DatePickerTranslated({ setDatePickerValue, datePickerValue }) {
  // const [datePickerValue, setDatePickerValue] = React.useState();

  // console.log("datePickerValue", moment(newValue.$d).format("YYYY-MM-DD"));
  const handleDay = day => {
    if (day === "2ª") {
      return "Dom";
    } else if (day === "3ª") {
      return "Seg";
    } else if (day === "4ª") {
      return "Ter";
    } else if (day === "5ª") {
      return "Qua";
    } else if (day === "6ª") {
      return "Qui";
    } else if (day === "Sa") {
      return "Sáb";
    } else {
      return "Dom";
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt"}>
      <Stack spacing={3}>
        <DatePicker
          sx={{ border: "none !important" }}
          value={datePickerValue}
          onChange={newValue => setDatePickerValue(moment(newValue.$d).format("YYYY-MM-DD"))}
          renderInput={params => <CssTextField {...params} />}
          dayOfWeekFormatter={day => handleDay(day)}
          toolbarFormat="ddd DD MMMM"
          inputProps={{
            placeholder: "dd/mm/aaaa",
          }}
          // showToolbar
        />
      </Stack>
    </LocalizationProvider>
  );
}
