import { combineReducers } from "redux";
import loginUsers from "./login";
import officeReducer from "./office/index";
import profileReducer from "./advogadoProfile/index";
import advocateListReducer from "./advocateList/index";
import clientListReducer from "./clientList/index";
import gaitsListReducer from "./gaits/index";
import getProfileReducer from "./profile/index";
import registerReducer from "./Register/index";
import loginPlanResponseReducer from "./storeLoginResponse/index";

const rootReducer = combineReducers({
  loginUsers,
  officeReducer,
  profileReducer,
  advocateListReducer,
  clientListReducer,
  gaitsListReducer,
  getProfileReducer,
  registerReducer,
  loginPlanResponseReducer,
});

export default rootReducer;
