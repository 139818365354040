import {
  GET_CLIENTE,
  ADD_CLIENTE,
  GET_NOTE_LIST,
  GET_NOTE_TYPE,
  ADD_NOTE,
  CHANGE_CLIENT_NOTE_STATUS,
  DELETE_CLIENT,
  GET_ALL_CLIENT,
} from "../url";
import { AfterAuthApi } from "..";

export const getClient = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_CLIENTE, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getAllClient = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ALL_CLIENT, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const addClient = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_CLIENTE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getNoteType = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTE_TYPE, "get")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getNoteList = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTE_LIST, "get")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addNote = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_NOTE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const changeNoteStatus = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(CHANGE_CLIENT_NOTE_STATUS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const deleteClient = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_CLIENT, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};
