import React from "react";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";

const PlusBottom = ({ handleClick }) => {
  const navigate = useNavigate();
  //   const handleClick = () => {
  //     console.log("clicked", url);
  //     navigate(url);
  //     console.log("navigate", navigate);
  //   };

  return (
    <div>
      <Box
        sx={{ position: "fixed", bottom: "0px", right: 0, zIndex: 99 }}
        px={1}
        display={"flex"}
        justifyContent={"end"}
        onClick={handleClick}>
        <AddCircleIcon sx={{ fontSize: "4rem", color: "red", cursor: "pointer" }} />
      </Box>
    </div>
  );
};

export default PlusBottom;
