import React, { useEffect, useState } from "react";

import { Card, CardContent, Grid } from "@mui/material";
import TarefaCard from "../Tarefa/TarefaCard";
import DeleteTarefaModal from "./DeleteTarefaModal";
import PlusBottom from "../PlusBottom";
import { useLocation, useNavigate } from "react-router-dom";
import { getProcessTask } from "../../helper/API/process";
import Loader from "../Loader";
import RecordFound from "../RecordFound/RecordFound";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const TarefaTab = ({ classes, permission }) => {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [processtask, setProcessTask] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const processData = JSON.parse(localStorage.getItem("process"));
  const navigate = useNavigate();
  const params = useLocation();
  // console.log("params", params);

  useEffect(() => {
    setLoading(true);
    const submitData = { processId: processData.id };
    getProcessTask(submitData)
      .then(res => {
        // console.log("res et task of process", res);
        setLoading(false);
        if (res.success) {
          setProcessTask(res.data);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch(err => setLoading(false));
  }, [refresh]);

  const handleClickOpen = id => {
    setOpen(true);
    setDeleteId(id);
    // console.log("id", id);
  };

  const handleClick = data => {
    if (data) {
      navigate("/home/information", { state: { process: params?.state?.TaskDetail } });
      localStorage.setItem("processId", params.state.TaskDetail.id);
    } else {
      navigate("/home/information", { state: { process: params?.state?.TaskDetail } });
      localStorage.setItem("processId", params.state.TaskDetail.id);
    }
  };
  const EditProcessTask = data => {
    navigate(`/home/information/${data.id}`, { state: { id: data.id, data: data } });
  };

  return (
    <div>
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
        {permission?.isOwner ? <PlusBottom url="/information" handleClick={handleClick} /> : ""}
        <Grid item xs={12} sm={12} md={12}>
          <Card variant="elevation">
            <CardContent>
              {loading ? (
                <Loader />
              ) : processtask?.length ? (
                processtask?.map((obj, i) => (
                  <TarefaCard
                    style={{ background: "#F3F4F9", borderRadius: "17.8784px", marginBottom: "1rem" }}
                    txtColor={"#6975FF"}
                    timeColor={"#9DA2D6"}
                    avatar={2}
                    lastTxt={"Hoje"}
                    handleClickOpen={handleClickOpen}
                    data={obj}
                    handleClick={handleClick}
                    EditProcessTask={EditProcessTask}
                    permission={permission}
                  />
                ))
              ) : (
                <RecordFound label="Nenhuma tarefa encontrada" />
              )}

              {/* <TarefaCard
                style={{ background: "#F3F4F9", borderRadius: "17.8784px", marginBottom: "1rem" }}
                txtColor={"#6975FF"}
                timeColor={"#9DA2D6"}
                avatar={3}
                lastTxt={"Hoje"}
                handleClickOpen={handleClickOpen}
              />

              <TarefaCard
                style={{ background: "#FEF9F3", borderRadius: "17.8784px", marginBottom: "1rem" }}
                txtColor={"#FF9929"}
                timeColor={"#D7BA9B"}
                avatar={3}
                lastTxt={"Hoje"}
                handleClickOpen={handleClickOpen}
              />

              <TarefaCard
                style={{ background: "rgba(255, 137, 116, 0.22)", borderRadius: "17.8784px", marginBottom: "1rem" }}
                txtColor={"#FF8974"}
                timeColor={"#FF8974"}
                avatar={2}
                lastTxt={"Hoje"}
                handleClickOpen={handleClickOpen}
              />

              <TarefaCard
                style={{ background: "#F3F4F9", borderRadius: "17.8784px", marginBottom: "1rem" }}
                txtColor={"#6975FF"}
                timeColor={"#9DA2D6"}
                avatar={3}
                lastTxt={"Amanhã"}
                handleClickOpen={handleClickOpen}
              /> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {open && (
        <DeleteTarefaModal handleClose={() => setOpen(false)} open={open} deleteId={deleteId} setRefresh={setRefresh} refresh={refresh} />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProps)(TarefaTab);
