import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { alpha } from "@mui/material";
import * as loginAction from "../redux/login/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import ConfirmDelete from "./DeleteAdvocate/ConfirmDelete";

const HoverMenu = ({ open, handleClick, handleClose, anchorEl, logoutUser }) => {
  const menuStyles = {
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: 25,
      minWidth: 180,
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 3px 14px 2px rgba(0,0,0,0.15)",
    },
  };

  const StyledMenu = styled(props => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 200,

      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 3px 14px 2px rgba(0,0,0,0.15)",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  const { logout } = useAuth();
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  const logOutUser = () => {
    logout();
    logoutUser();
    localStorage.clear();
    navigate("/");
  };
  const handleCloseDropDown = () => {
    setOpenConfirm(true);
    handleClose();
  };

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        // classes={menuStyles}
        sx={{ width: "200px" }}>
        <MenuItem onClick={handleCloseDropDown} sx={{ color: "red" }}>
          Deletar conta
        </MenuItem>
        <MenuItem onClick={logOutUser}>Sair</MenuItem>
      </StyledMenu>
      {openConfirm && <ConfirmDelete open={openConfirm} handleClose={() => setOpenConfirm(false)} />}
    </div>
  );
};

const mapDispatchToProp = dispatch => {
  return {
    logoutUser: () => dispatch(loginAction.logout()),
  };
};
export default connect(null, mapDispatchToProp)(HoverMenu);
