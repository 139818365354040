import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const EquipCard = ({ data, msgTime }) => {
  // console.log("data", data);
  return (
    <div>
      {/* <Container sx={{ padding: 0 }}>
                <Grid sx={{ marginTop: "0.5rem" }}>
                    <div style={{ border: "1.47059px solid rgba(117, 117, 117, 0.3)", padding: "0.3rem 1rem", borderRadius: "14.7059px", color: "#636C84" }}> */}

      <Grid container sx={{ marginBottom: "3rem", padding: "1rem" }}>
        <Grid item xs={3} sm={1} md={1} sx={{}}>
          <img src={data?.avatar} height="50px" width="50px" style={{ borderRadius: "50%" }} />
        </Grid>
        <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%" }}>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} sx={{ color: "#000000", fontWeight: 500 }}>
              {data?.from}
            </Grid>
            <Grid item xs={6} sm={6} md={6} sx={{ textAlign: "end" }}>
              {data.dateString}
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ paddingRight: "1rem", marginTop: "1rem" }}>
              {data?.message}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container sx={{ marginBottom: "3rem", padding: "1rem" }}>
                            <Grid item xs={3} sm={1} md={1} sx={{}}>
                                <img src="/assets/images/dummyImg.png" height="50px" style={{ borderRadius: "50%" }} />
                            </Grid>
                            <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%" }}>
                                <Grid container >
                                    <Grid item xs={6} sm={6} md={6} sx={{ color: "#000000", fontWeight: 500 }}>Marcos Fonseca</Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ textAlign: "end" }}>20 de fev 16:30</Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ paddingRight: "1rem", marginTop: "1rem" }}>Boa tarde Erica, tudo bem? Você consegue enviar os documentos que comentei com a senhora por aqui? Obrigada.Boa tarde Erica, tudo bem? Você consegue enviar os documentos que comentei com a senhora por aqui? Obrigada.</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: "1rem", cursor: "pointer" }}>
                            <Grid item xs={3} sm={1} md={1} sx={{}}>

                            </Grid>
                            <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%", display: "flex" }}>
                                <img src="/assets/images/share.png" />
                                <Typography sx={{ color: "#0085FF", marginLeft: "1rem" }}>Responder</Typography>
                            </Grid>
                        </Grid> */}
      {/* </div>
                </Grid>
            </Container> */}
    </div>
  );
};

export default EquipCard;
