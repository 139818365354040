import React from 'react'
import { Box, Button, Card, CardContent, MenuItem, TextareaAutosize, Typography } from '@mui/material'
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, InputAdornment, OutlinedInput, TextField, } from '@mui/material';

import SwitchUnstyled, {
    switchUnstyledClasses
} from "@mui/base/SwitchUnstyled";

const currencies = [
    {
        value: 'Presencial',
        label: 'Presencial',
    },

];
const Reuniaos = [
    {
        value: 'Audiência',
        label: 'Audiência',
    },

];

const blue = {
    500: "#5031A9"
};

const grey = {
    400: "#BFC7CF",
    500: "#AAB4BE",
    600: "#6F7E8C"
};

const Root = styled("span")(
    ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
      border-radius: 10px;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 3px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;
      transition: all 200ms ease;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: ${grey[500]};
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 22px;
        top: 3px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background: ${blue[500]};
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `
);

const Img = styled("img")({
    display: "block",
    //   maxWidth: "40%",
    //   maxHeight: "100%",
});

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        borderRadius: 160,
        // maxWidth: 500,
        "& .MuiButtonBase-root": {
            background: "red"
        }

    },


    card: {
        // marginBottom: "1rem",
        boxShadow: "unset !important",
        borderRadius: "14px",

    },
});

function Alertas() {

    const [currency, setCurrency] = React.useState('Presencial');
    const [Reuniao, setReuniao] = React.useState('Audiência');

    const classes = useStyles();
    const navigate = useNavigate()


    const handleChange = (event) => {
        setCurrency(event.target.value);
    };
    const handleReuniaoChange = (event) => {
        setReuniao(event.target.value);
    };

    const handleGaits = () => {
        navigate("/home/gaits")
    }


    return (
        <div>
            <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>

                <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "start", marginBottom: "2rem" }}>
                    <Typography >Voltar</Typography>

                </Box>

                <Card variant="elevation" className={classes.card} sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2, }} >

                    <CardContent sx={{ position: "relative" }}>

                        <Box>
                            <Typography variant='h4'>Informações</Typography>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Box >
                                <Grid container spacing=
                                    {2}>


                                    <Grid item md={12} sx={{ mt: 3 }}>
                                        <FormControl variant="outlined" fullWidth >
                                            <OutlinedInput
                                                id="outlined-adornment-weight"
                                                placeholder='Ir no fórum'

                                                aria-describedby="outlined-weight-helper-text"

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sx={{ mt: 3 }}>
                                        <FormControl variant="outlined" fullWidth >
                                            <OutlinedInput
                                                id="outlined-adornment-weight"
                                                placeholder='11-06-2022'
                                                // value={values.weight}
                                                // onChange={handleChange('weight')}
                                                endAdornment={<InputAdornment position="end">
                                                    <img src="/assets/images/calendarDay.png" />
                                                </InputAdornment>}
                                                aria-describedby="outlined-weight-helper-text"

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sx={{ mt: 3 }}>
                                        <FormControl variant="outlined" fullWidth >
                                            <OutlinedInput
                                                id="outlined-adornment-weight"
                                                placeholder='13:00'
                                                // value={values.weight}
                                                // onChange={handleChange('weight')}
                                                endAdornment={<InputAdornment position="end">
                                                    <img src="/assets/images/clock.png" />
                                                </InputAdornment>}
                                                aria-describedby="outlined-weight-helper-text"

                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* Skype */}
                                    <Grid item md={8} sx={{ mt: 3 }}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Presencial"
                                            value={currency}
                                            onChange={handleChange}
                                            fullWidth
                                        >
                                            {currencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>

                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={4} sx={{ mt: 3 }}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Select"
                                            value={Reuniao}
                                            onChange={handleReuniaoChange}
                                            fullWidth
                                        >
                                            {Reuniaos.map((option) => (
                                                <MenuItem key={option.value} value={option.value} sx={{}}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img src="/assets/images/OvalRed.png" style={{ paddingRight: 5, height: 20 }} />
                                                        {option.label}
                                                    </div>
                                                    <Typography></Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {/* TextArea */}
                                    <Grid item md={12} sx={{ mt: 3 }}>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={10}
                                            // maxCol={8}
                                            variant="outlined"
                                            placeholder="Não esquecer!"
                                            style={{ border: "2px solid #DBD7F4", borderRadius: "13px", width: "100%", resize: "none" }}
                                        />
                                    </Grid>

                                    <Grid item md={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                        <Typography sx={{ fontWeight: 400, color: "#222B45" }}>Lembrar</Typography>
                                        <SwitchUnstyled component={Root} defaultChecked />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

            </Box>
        </div>)
}

export default Alertas