import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ButtonBase, Grid, Input, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import HoverMenu from "../components/HoverMenu";
import NotificationMenu from "../components/NotificationMenu";
import { connect } from "react-redux";
import { getDashboardNotification, getNotificationCount } from "../helper/API/dashboard";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import { searchProcessList } from "../helper/API/process";
import CancelIcon from "@mui/icons-material/Cancel";
const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Navbar = ({ pages, userData }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);
  const [notificationCount, setNotificationCount] = React.useState();
  const [notifications, setNotifications] = React.useState([]);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorElNotification);

  const navigate = useNavigate();
  const { user, logout } = useAuth();

  React.useEffect(() => {
    getNotificationCount().then(res => {
      // console.log("res count", res);
      if (res.success) {
        setNotificationCount(res.data);
      }
    });
    // getDashboardNotification().then(res => {
    //
  }, [openNotification]);

  const handleNotificationClick = event => {
    setAnchorElNotification(event.currentTarget);
    // console.log("clicked");
    getDashboardNotification().then(res => {
      if (res.success) {
        setNotifications(res.data);
      }
    });
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHover = () => {
    // console.log("hover div");
  };

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path, index) => {
    setActiveIndex(index);
    setAnchorElNav(null);
    if (path) {
      navigate(path);
    }
  };
  const [codProcesso, setCodProcesso] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleSearchChange = event => {
    setCodProcesso(event.target.value);
  };

  const handleSubmitSearch = () => {
    if (codProcesso) {
      navigate(`/home/processos/${codProcesso}`);
      window.location.reload();
    } else {
      navigate(`/home/processos`);
    }
  };
  const handleClearInput = () => {
    setCodProcesso("");
    navigate(`/home/processos`);
  };
  // console.log("notificationCount", notificationCount);
  const onEnter = e => {
    if (e.key === "Enter") {
      if (codProcesso) {
        navigate(`/home/processos/${codProcesso}`);
      } else {
        navigate(`/home/processos`);
      }
    }
  };
  return (
    <MuiAppBar position="static" sx={{ backgroundColor: "#fff", boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: "#000" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              {pages?.map((page, index) => (
                <MenuItem key={page.label} onClick={() => handleCloseNavMenu(page.path, index)}>
                  <Container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                    <div style={{ width: 25, height: 25 }}>
                      <Img src={page.img} />
                    </div>

                    {page.label}
                  </Container>
                </MenuItem>
              ))}
              {!!user && (
                <MenuItem key={"logout"} onClick={""}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex", backgroundColor: "#fff" } }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button sx={{ width: 125, height: 125 }} onClick={() => navigate("/home")}>
                  <Img alt="complex" src="/assets/images/inova_juris_logo.png" />
                </Button>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item md={10}>
                  <Grid container>
                    {pages?.map((page, index) => (
                      <Grid
                        item
                        xs={8}
                        md={2}
                        key={page.label}
                        onClick={() => handleCloseNavMenu(page.path, index)}
                        sx={{ mt: 2, color: "#734699", display: "block", fontWeight: `${index == activeIndex ? "bold" : "300"}` }}>
                        <Container sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", cursor: "pointer" }}>
                          <div style={{ width: 25, height: 25 }}>
                            <Img src={page.img} />
                          </div>

                          {page.label}
                        </Container>
                      </Grid>
                    ))}
                    {/* {!!user && (
                      <Grid item xs={8} md={2} key={"logout"} onClick={""} sx={{ color: "white", display: "block" }}>
                        {"logout"}
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item xs={7} md={7} sx={{ mt: 2, mb: 2, color: "#734699", display: "block" }}>
                      <Container sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        {/* <Box
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%',
                                                    }}
                                                > */}

                        {/* SEARCHBAR NAV */}
                        <TextField
                          fullWidth
                          label="Pesquisar processo"
                          variant="outlined"
                          id="fullWidth"
                          value={codProcesso}
                          onChange={handleSearchChange}
                          onKeyPress={onEnter}
                          InputProps={{
                            endAdornment: (
                              <>
                                <InputAdornment position="end">
                                  <IconButton type="button" sx={{ p: "0", display: "flex", alignItems: "center" }} aria-label="search">
                                    <SearchIcon onClick={handleSubmitSearch} />
                                  </IconButton>
                                </InputAdornment>
                                {codProcesso && (
                                  <InputAdornment position="end">
                                    <IconButton type="button" sx={{ p: "0", display: "flex", alignItems: "center" }} aria-label="search">
                                      <CancelIcon onClick={handleClearInput} sx={{ ml: 1 }} />
                                    </IconButton>
                                  </InputAdornment>
                                )}
                              </>
                            ),
                          }}
                        />
                      </Container>
                    </Grid>

                    <Grid item xs={2} md={5} sx={{ color: "#000", display: "flex", alignItems: "center", mt: 2, mb: 2 }}>
                      <Container
                        id="notification-button"
                        aria-controls={openNotification ? "notification-button" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openNotification ? "true" : undefined}
                        onClick={handleNotificationClick}
                        sx={{
                          width: 55,
                          height: 55,
                          py: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(185, 97, 254, 0.2)",
                          position: "relative",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}>
                        <img alt="bell" src="/assets/images/bell.png" height={25} />
                        <Box
                          sx={{
                            position: "absolute",
                            top: "-15%",
                            right: "-15%",
                            border: "1px solid #734699",
                            borderRadius: "50%",
                            height: "20px",
                            width: "20px",
                            background: "#734699",
                            display: "flex",
                            justifyContent: "center",
                          }}>
                          <Typography
                            textAlign="center"
                            sx={{ position: "absolute", top: "10%", color: "#fff", fontSize: "10px", fontWeight: "900" }}>
                            {notificationCount ? notificationCount : 0}
                          </Typography>
                        </Box>
                      </Container>
                      <NotificationMenu
                        open={openNotification}
                        handleClick={handleNotificationClick}
                        handleClose={() => setAnchorElNotification(null)}
                        anchorEl={anchorElNotification}
                        notificationData={notifications}
                      />
                      <Link to="dados">
                        <Container
                          sx={{
                            width: 55,
                            height: 55,
                            py: 1,
                            display: "flex",
                            justifyContent: "center",
                            background: "#FFEBE7",
                            position: "relative",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <AccountBalanceWalletTwoToneIcon height={"100%"} sx={{ color: "#FF6D4D !important" }} />

                          {/* <Box
                            sx={{
                              position: "absolute",
                              top: "-15%",
                              right: "-15%",
                              border: "1px solid #FF6D4D",
                              borderRadius: "50%",
                              height: "20px",
                              width: "20px",
                              background: "#FF6D4D",
                            }}>
                            <Typography
                              textAlign="center"
                              sx={{ position: "absolute", right: "30%", top: "-20%", color: "#fff", fontSize: "20px", fontWeight: "900" }}>
                              !
                            </Typography>
                          </Box> */}
                        </Container>
                      </Link>
                      <Container
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                          cursor: "pointer",
                          width: 200,
                          py: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "#734699",
                          position: "relative",
                          py: 1,
                          height: 56,
                          borderRadius: "8px",
                          color: "#fff",
                          fontSize: "15px",
                        }}>
                        {userData?.name}
                        <Box
                          sx={{
                            position: "absolute",
                            right: "-20%",
                            borderRadius: "50%",
                            background: "#000",
                            border: "3px solid #fff",
                            height: 56,
                            width: 56,
                            top: "-5%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <img
                            src={userData?.avatar ? userData?.avatar : "/assets/images/inova_juris_logo.png"}
                            textAlign="center"
                            style={{ height: 56, width: 56, border: "3px solid #fff", borderRadius: "50%" }}
                          />
                        </Box>
                      </Container>
                      <HoverMenu open={open} handleClick={handleClick} handleClose={() => setAnchorEl(null)} anchorEl={anchorEl} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};

const mapStateToProp = state => {
  // console.log("state", state);
  return {
    userData: state?.loginUsers?.loginUser?.user,
  };
};

export default connect(mapStateToProp)(Navbar);
