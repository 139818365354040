import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const HoverMenu = ({ open, handleClick, handleClose, anchorEl, setSelectVal, dropDownValue }) => {
  const StyledMenu = styled(props => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 200,
      backgroundColor: "#F3F2F7",
      boxShadow: "0px -1px 12px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 9px 13px rgb(0 0 0 / 49%)",
      borderRadius: "11.1148px",

      //   boxShadow: "0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 3px 14px 2px rgba(0,0,0,0.15)",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        backgroundColor: "#F3F2F7 !important",
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.primary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  return (
    <div>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        // classes={menuStyles}
        sx={{ width: "200px" }}>
        <MenuItem
          onClick={() => {
            setSelectVal("client");
            handleClose();
          }}>
          {" "}
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <TaskAltIcon style={{ color: "#73469980" }} />
            <Typography sx={{ color: "#5E6C93" }} variant="body1">
              cliente
            </Typography>
          </Box>
        </MenuItem>
        {dropDownValue.map((obj, index) => (
          <>
            <MenuItem
              onClick={() => {
                setSelectVal(obj.id);
                handleClose();
              }}>
              {" "}
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <TaskAltIcon style={{ color: "#73469980" }} />
                <Typography sx={{ color: "#5E6C93" }} variant="body1">
                  {obj.label}
                </Typography>
              </Box>
            </MenuItem>
          </>
        ))}
      </StyledMenu>
    </div>
  );
};
export default HoverMenu;
