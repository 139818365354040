import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InsertDriveFileOutlined, Done } from "@mui/icons-material";
import { Chip } from "@mui/material";

function createData(invoice, date, status, valor, plano) {
  return { invoice, date, status, valor, plano };
}

const rows = [
  createData("May", "23.5.2022", "Payment", "R$49", "Premium"),
  createData("April", "23.4.2022", "Payment", "R$49", "Premium"),
  createData("March", "23.3.2022", "Payment", "R$49", "Premium"),
  createData("February", "23.2.2022", "Payment", "R$49", "Premium"),
  createData("January", "23.1.2022", "Payment", "R$49", "Premium"),
];

const SimpleTable = ({ cardData, tableData }) => {
  const [data, setData] = React.useState(tableData);
  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);
  // console.log("data", data);
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Faturas</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">
              Data de cobrança
            </TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">
              Status
            </TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">
              Valor
            </TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">
              Plano
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((obj, i) => (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      marginRight: 8,
                      color: "#7F56DA",
                      fontSize: 8,
                      fontWeight: 500,
                      background: "#F8F5FE",
                      borderRadius: "83.9286px",
                      padding: 7,
                    }}>
                    <InsertDriveFileOutlined />
                  </span>
                  {obj?.date}
                </div>
              </TableCell>
              <TableCell align="center">{obj?.tempDate}</TableCell>
              <TableCell align="center">
                {obj?.planStatus === "Payment" ? (
                  <Chip
                    sx={{
                      background: "#EFFCF4",
                      color: "#34774C",
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                    avatar={<Done sx={{ color: "#34774C !important" }} />}
                    label={obj?.planStatus}
                  />
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell align="center">R$ {numberWithCommas(obj?.planPrice)}</TableCell>
              <TableCell align="center">{obj?.planName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
