import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Button, Grid } from "@mui/material";
import DropdownFilterModal from "../components/Processos/DropDownFilterModal";
import { Container } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import RegisteredProcessesCard from "../components/Processos/RegisteredProcessesCard";
import { deleteProcessAPI, getProcess, searchProcessList } from "../helper/API/process";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import ProcessosHeader from "../components/Processos/ProcessosHeader";
import RecordFound from "../components/RecordFound/RecordFound";
import { connect } from "react-redux";
import SubscribedProccessModal from "../components/Processos/SubscribedProccessModal";
import { useLocation, useParams } from "react-router-dom";
import { DeleteProcessModal } from "../components/Processos/DeleteProccessModal";

import {  deleteValidatingProcess, registerValidatingProcess } from "../helper/API/process";
import ValidatingProcessCard from "../components/Processos/ValidatingProcessCard";

const Img = styled("img")({
  display: "block",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "red",
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const StyledButton = styled(Button)`
  &:hover {
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
`;

const ProcessosPage = ({ permission }) => {
  const classes = useStyles();
  const params = useParams();
  //console.log("params", params);

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [codProcesso, setCodProcesso] = React.useState(params.id);
  const [processList, setProcessList] = React.useState([]);
  const [refresh, setRefresh] = React.useState(0);
  const [isSearchResult, setIsSearchResult] = React.useState(false);
  const [processModal, setProcessModal] = React.useState(false);
  const [processModalData, setProcessModalData] = React.useState([]);
  const location = useLocation();
  const [proccessIsInDelete, setProccessIsInDelete] = useState(null);

  const [formValues, setFormValues] = useState({
    client: "",
    attorney: "",
    status: "",
    minAmount: "",
    maxAmount: "",
    // fromDate: "",
    // toDate: "",
  });

  useEffect(() => {
    setCodProcesso(params.id);
  }, [params.id]);

  // console.log("location", location);
  useEffect(() => {
    setLoading(true);
    // console.log("called loading");
    setIsSearchResult(false);
    // console.log("called");
    let processSearchRequest;
    if (params.id) {
      const submitData = { numProcesso: params.id };
      processSearchRequest = searchProcessList(submitData)
    } else {
      processSearchRequest = getProcess();
    }

    processSearchRequest.then(res => {
      console.log('res : ', res);
      if (res.success) {
        console.log('success res.data : ', res.data);
        setIsSearchResult(false);
        setProcessList(res.data);
        setLoading(false);
      } else {
        console.log('else res.data : ', res.data);
        setIsSearchResult(false);
        toast.error(res.message);
        setLoading(false);
      }
    });
  }, [refresh]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSearchChange = event => {
    setCodProcesso(event.target.value);
  };

  const handleSubmitSearch = () => {
    setLoading(true);
    // console.log("called");
    setIsSearchResult(true);
    if (codProcesso) {
      // console.log("called loading");
      const submitData = { numProcesso: codProcesso };
      searchProcessList(submitData).then(res => {
        if (res.success) {
          setProcessList(res.data);
          setLoading(false);
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      });
    } else {
      setRefresh(refresh + 1);
    }
  };

  

  const openDeleteProcessModal = (processId) => {
    setProccessIsInDelete(processId);
  };

  const closeDeleteProcessModal = () => {
    setProccessIsInDelete(null);
  };

  const deleteProcess = async () => {
    const response = await deleteProcessAPI({
      id: proccessIsInDelete,
    });

    if (response.success) {
      toast.success(response.message);
      setRefresh(refresh + 1);
    } else {
      toast.error(response.message);
    }

    closeDeleteProcessModal();
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        {permission?.isOwner && permission?.processMgt && (
          <div>
            {/* <Typography sx={{ padding: "0px 13px" }}>Número do processo CNJ*</Typography> */}
            <CustomizedSearchBar
              onChange={handleSearchChange}
              handleSubmitSearch={handleSubmitSearch}
              placeholder="Número do processo CNJ*"
              codProcesso={codProcesso}
            />
            <Typography sx={{ padding: "0px 13px" }}>NNNNNNN-DD.AAAA.J.TR.OOOO</Typography>
          </div>
        )}  
        <div>
          <ValidatingProcessCard />
          <ProcessosHeader
            subscribed={processList ? processList.length : 0}
            formValues={formValues}
            handleOpen={handleOpen}
            setRefresh={setRefresh}
            refresh={refresh}
            totalLimit={permission?.noOfProcess}
            isSearchResult={isSearchResult}
            setProcessModal={setProcessModal}
            setProcessModalData={setProcessModalData}
            permission={permission}
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {processList.length ? (
              <>
                {processList?.map((obj, indx) => (
                  <RegisteredProcessesCard
                    taskList={obj}
                    setProcessList={setProcessList}
                    setLoading={setLoading}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    handleDelteProcess={openDeleteProcessModal}
                  />
                ))}
              </>
            ) : (
              <RecordFound label="Pesquisar por Processo" />
            )}
          </>
        )}
        {proccessIsInDelete && (
          <DeleteProcessModal
            open={proccessIsInDelete}
            handleClose={closeDeleteProcessModal}
            handleDelete={deleteProcess}
          />
        )}
        {open && (
          <DropdownFilterModal
            setFormValues={setFormValues}
            formValues={formValues}
            open={open}
            handleClose={() => setOpen(false)}
            setProcessList={setProcessList}
            setLoading={setLoading}
          />
        )}
        {permission?.isOwner && permission?.processMgt && processModal && (
          <SubscribedProccessModal
            open={processModal}
            handleClose={() => setProcessModal(false)}
            processModalData={processModalData}
            closeModal={() => {
              setProcessModal(false);
              setRefresh(refresh + 1);
            }}
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProps)(ProcessosPage);
