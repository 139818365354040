import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { Container } from "@mui/system";
import AndamentosCard from "../Processos/AndamentosCard";
import { useLocation } from "react-router-dom";
import { addGaitsResponse, getProcessGaits } from "../../helper/API/process";
import TimeStampCard from "./TimeStampCard";
import ScrollToBottom from "react-scroll-to-bottom";
import Loader from "../Loader";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as gaits from "../../redux/gaits/action";
import { useRef } from "react";
import { set } from "date-fns";

const AndamentosTab = ({ classes, loginUser, gaitsListFromRedux, processing, gaitsListData, admentosId }) => {
  const loginUserId = loginUser.loginUsers.loginUser?.user?._id;
  const params = useLocation();
  const ids = params?.state?.TaskDetail;
  const [admentos, setAdmentos] = useState([]);
  const [responseStr, setResponseStr] = useState("");
  const [loading, setLoading] = useState(processing);
  const [showId, setshowId] = useState("");
  const [formValues, setFormValue] = useState({
    id: "",
    codAndamento: "",
    userType: "advocate",
    userId: loginUserId,
    response: "",
  });
  const processData = JSON.parse(localStorage.getItem("process"));

  const elem = useRef("");
  useEffect(() => {
    const submitData = { codProcesso: processData?.codProcesso, processId: processData?.id };
    // console.log("submitData", submitData);
    gaitsListData(submitData);
    // setLoading(true);
    // getProcessGaits(submitData).then(res => {
    //   if (res.success) {
    //     setAdmentos(res.data);
    //     setLoading(false);
    //   } else {
    //     setLoading(false);
    //   }
    //   console.log("res in get gaits", res);
    // });
  }, []);
  // console.log("admentos", admentos);

  const hideShowInput = id => {
    setshowId(id);
  };

  const handleResponseChange = (e, obj) => {
    setFormValue({
      ...formValues,
      [e.target.name]: e.target.value,
      id: admentosId,
      codAndamento: obj.codAndamento,
    });
    // console.log("elem.current.valuefdfdsf", elem.current.value);
  };

  const onEnterResponse = (e, gaitsData) => {
    let newFormValues = {
      id: admentosId,
      codDocumento: gaitsData.codDocumento,
      userType: "advocate",
      userId: loginUserId,
      response: elem.current.value,
    };
    // console.log("newFormValues", newFormValues);
    if (e.key === "Enter" && formValues.response.trim()) {
      // console.log("formValues", formValues);
      // setLoading(true);

      addGaitsResponse(formValues).then(res => {
        if (res.success) {
          const submitData = { codProcesso: ids.codProcesso, processId: ids.id };
          gaitsListData(submitData);
          toast.success(res.message);
          setFormValue({
            id: "",
            codDocumento: "",
            userType: "advocate",
            userId: loginUserId,
            response: "",
          });
        } else {
          toast.error(res.message);
          // setLoading(false);
        }
      });
      setshowId("");
      // setLoading(false);
    }
  };

  const onSubmitResponse = gaitsData => {
    // setLoading(true);
    // console.log("value 👉️", elem.current.value);

    // console.log("elem.current.value", elem.current.value);
    addGaitsResponse(formValues).then(res => {
      // console.log("res gaits response ::::", res);
      if (res.success) {
        const submitData = { codProcesso: ids.codProcesso, processId: ids.id };
        gaitsListData(submitData);
        toast.success(res.message);
        setFormValue({
          id: "",
          codDocumento: "",
          userType: "advocate",
          userId: loginUserId,
          response: "",
        });
      } else {
        toast.error(res.message);
        // setLoading(false);
      }
    });
    setshowId("");
    // setLoading(false);
  };

  const addOneDay = (dateString) => {
    console.log('dateString', dateString);
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    
    // Add one day directly to the Date object
    date.setDate(date.getDate() + 2);

    // Format the date to "DD/MM/YYYY"
    const newDay = date.getDate().toString().padStart(2, '0');
    const newMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const newYear = date.getFullYear();
    
    // Return the formatted date string
    const newDate = `${newDay}/${newMonth}/${newYear}`;

    return newDate;
};

const dayOne = '24/01/2024';
addOneDay(dayOne);

  

  return (
    <div>
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
        <Grid item xs={12} sm={12} md={12}>
          <Card variant="elevation">
            <div>
              {/* <ScrollToBottom mode="bottom" initialScrollBehavior="smooth"> */}
              {processing ? (
                <Loader />
              ) : (
                <>
                  <CardContent sx={{ height: "55vh", overflowY: "scroll", display: "flex", flexDirection: "column-reverse" }}>
                    {gaitsListFromRedux?.length ? (
                      gaitsListFromRedux?.map((obj, indx) => (
                        <div key={indx}>
                          {/* {JSON.stringify(obj, null, 3)} */}
                          <TimeStampCard time={addOneDay(obj.date)}/>
                          <AndamentosCard
                            gaitsData={obj}
                            showId={showId}
                            hideShowInput={hideShowInput}
                            handleResponseChange={handleResponseChange}
                            onEnterResponse={onEnterResponse}
                            onSubmitResponse={onSubmitResponse}
                            elem={elem}
                            setResponseStr={setResponseStr}
                          />
                        </div>
                      ))
                    ) : (
                      <Box sx={{ display: "flex", justifyContent: "center", height: "100%", color: "#7a7a7a" }}>
                        <h2>Nenhum registro foi encontradoI</h2>
                      </Box>
                    )}
                  </CardContent>
                </>
              )}
              {/* </ScrollToBottom> */}
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProp = state => {
  return {
    loginUser: state,
    gaitsListFromRedux: state?.gaitsListReducer?.gaitsList,
    processing: state?.gaitsListReducer?.processing,
    admentosId: state?.gaitsListReducer?.admentos?.id,
  };
};

const mapDispatchToProp = dispatch => {
  return {
    gaitsListData: submitData => dispatch(gaits.gaitsList(submitData)),
  };
};

export default connect(mapStateToProp, mapDispatchToProp)(AndamentosTab);

