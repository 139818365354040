import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Calander = ({ setMonth, task, setTaskDate, taskData, setTaskYear, taskYear }) => {
  // console.log("task in calendar", taskData);
  // console.log("taskData", task);
  let Currentdates = [];
  let Lastdates = [];
  let Nextdates = [];
  let currentDate = 0,
    PrevDate = 0,
    NextDate = 0;

  let date = new Date(),
    currYear = date.getFullYear();
  let MainEvents = [2, 3, 6, 8, 10, 15, 17, 20, 22, 23, 29, 31];
  let [currMonth, setCurrMonth] = useState(date.getMonth());
  let [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  let [activeYear, setActiveYear] = useState(0);
  const months = [
    "Janeiro",
    "Fevereiro",
    "março",
    "abril",
    "maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "novembro",
    "dezembro",
  ];
  const renderCalendar = () => {
    let firstDayofMonth = new Date(taskYear, currMonth, 1).getDay(),
      lastDateofMonth = new Date(taskYear, currMonth + 1, 0).getDate(),
      lastDayofMonth = new Date(taskYear, currMonth, lastDateofMonth).getDay(),
      lastDateofLastMonth = new Date(taskYear, currMonth, 0).getDate();
    currentDate = months[currMonth];
    NextDate = months[(currMonth + 1) % 12];
    PrevDate = months[(currMonth + 11) % 12];
    // console.log("currentDate", currentDate);
    // console.log("currMonth", currMonth);
    // console.log("NextDate", NextDate);
    // console.log("PrevDate", PrevDate);
    // console.log("moment().month(currentDate).format", moment().month(currentDate).format("M"));
    for (let i = firstDayofMonth; i > 0; i--) {
      Lastdates.push({
        date: lastDateofLastMonth - i + 1,
        today: false,
      });
    }
    for (let i = 1; i <= lastDateofMonth; i++) {
      if (i === date.getDate() && currMonth === new Date().getMonth() && currYear === taskYear + activeYear) {
        // console.log("currYear ::", currYear);
        Currentdates.push({
          date: i,
          today: true,
          month: currentDate,
          event: false,
          dateString: `${currYear}-${
            moment().month(currMonth).format("M") <= 9 ? `0${moment().month(currMonth).format("M")}` : moment().month(currMonth).format("M")
          }-${i <= 9 ? `0${i}` : i}`,
        });
        // console.log("currYear else case ::", currYear);
      } else {
        // var curr = new Date();
        // let day = new Date(curr.setDate(i + 1)).toISOString().slice(0, 10);
        // console.log("day::", day);
        Currentdates.push({
          date: i,
          today: false,
          month: currentDate,
          event: false,
          dateString: `${currYear}-${
            moment().month(currMonth).format("M") <= 9 ? `0${moment().month(currMonth).format("M")}` : moment().month(currMonth).format("M")
          }-${i <= 9 ? `0${i}` : i}`,
        });
      }
    }
    for (let i = lastDayofMonth; i < 6; i++) {
      Nextdates.push({
        date: i - lastDayofMonth + 1,
        today: false,
      });
    }
  };
  // console.log("taskYear", taskYear);
  renderCalendar();
  const apiMonths = [
    { month: "Janeiro", number: 1 },
    { month: "Fevereiro", number: 2 },
    { month: "março", number: 3 },
    { month: "abril", number: 4 },
    { month: "maio", number: 5 },
    { month: "Junho", number: 6 },
    { month: "Julho", number: 7 },
    { month: "Agosto", number: 8 },
    { month: "Setembro", number: 9 },
    { month: "Outubro", number: 10 },
    { month: "novembro", number: 11 },
    { month: "dezembro", number: 12 },
  ];

  const curruntMonthNumber = apiMonths.filter((obj, i) => {
    return obj.month === currentDate;
  });
  useEffect(() => {
    setMonth(curruntMonthNumber);
  }, [currentDate]);

  function prev() {
    setCurrMonth((currMonth + 11) % 12);
    renderCalendar();
  }
  function next() {
    setCurrMonth((currMonth + 1) % 12);
    renderCalendar();
  }

  Currentdates.map(val => {
    MainEvents.map(MainVal => {
      if (val.date == MainVal && currMonth === new Date().getMonth() && currYear === new Date().getFullYear()) {
        val.event = true;
      }
    });
  });

  const setDateString = val => {
    setTaskDate(val.dateString);
    setActiveDate(val.dateString);
    // console.log("val :::", val);
  };

  // console.log("new Date().getFullYear()", new Date().getFullYear() + 1);

  const data = [{ month: new Date().getFullYear() - 1 }, { month: new Date().getFullYear() }, { month: new Date().getFullYear() + 1 }];
  // console.log("data:::::", data);
  return (
    <Grid className="wrapper" container direction="row" justifyContent="center" alignItems="center">
      {/* <Grid md={8} sm={12} xs={12} container columnSpacing={1} className="icons"> */}
      <Grid md={1} sm={3} xs={3} className="center icons">
        <span onClick={() => prev()} className=" prev-date">
          {PrevDate}
        </span>
      </Grid>
      <Grid md={2} sm={3} xs={3} className="center icons">
        <span className="current-date">{currentDate}</span>
      </Grid>
      <Grid md={1} sm={3} xs={3} className="center icons">
        <span onClick={() => next()} className=" next-date">
          {NextDate}
        </span>
      </Grid>
      <Grid md={2} sm={3} xs={3} className="center icons">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControl sx={{ width: "150px" }} variant="standard">
            {/* <InputLabel id="demo-simple-select-label">Encontro</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={taskYear}
              label="Encontro"
              onChange={e => setTaskYear(e.target.value)}>
              {data.map((obj, index) => (
                <MenuItem value={obj.month}>{obj.month}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* </Grid> */}
      </Grid>

      <Grid container md={12} sm={12} xs={12} className="icons">
        <Grid className="calendar">
          {/* <Grid md={7} container> */}

          <div className="weeks">
            <div className="fs">Dom</div>
            <div className="fs">Seg</div>
            <div className="fs">Ter</div>
            <div className="fs">Qua</div>
            <div className="fs">Qui</div>
            <div className="fs">Sex</div>
            <div className="fs">Sáb</div>
          </div>
          <Grid>
            <Grid md={12} className="weeks">
              {Lastdates.map(val => {
                return (
                  <Grid md={1} class="inactive li">
                    {val.date}
                  </Grid>
                );
              })}
              {Currentdates.map(val => {
                const taskFilter = task?.filter(obj => obj._id == val.dateString);
                // console.log("taskFilter", taskFilter.length);
                // console.log("taskFilter", taskFilter);
                // console.log("val.today === true", val.today);
                return (
                  <>
                    <Grid class={val + `${activeDate == val.dateString ? " active li" : " li"}`} onClick={() => setDateString(val)}>
                      <div>{val.date}</div>
                      {/* <DotsWithColor taskFilter={taskFilter} val={val} /> */}
                      {taskFilter[0]?.list.length >= 3 ? (
                        <div md={3} className="dote">
                          <span md={1} className="orange">
                            .
                          </span>
                          <span md={1} className="violet">
                            .
                          </span>
                          <span md={1} className="sky">
                            .
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {taskFilter[0]?.list.length == 2 ? (
                        <div md={3} className="dote">
                          <span md={1} className="orange">
                            .
                          </span>
                          <span md={1} className="violet">
                            .
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {taskFilter[0]?.list.length == 1 ? (
                        <div md={3} className="dote">
                          <span md={1} className="orange">
                            .
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </>
                );
              })}
              {Nextdates.map(val => {
                return <li class="inactive">{val.date}</li>;
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Calander;
